import React from "react";

const XIcon: React.FC = () => {
    return (
        <div className="social" data-testid="social" aria-label="x">
            <svg
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_2047_39427)">
                    <path
                        className="social-primary-color"
                        d="M36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18Z"
                        fill="#1F274B"
                    />
                    <mask
                        id="mask0_2047_39427"
                        maskUnits="userSpaceOnUse"
                        x="8"
                        y="8"
                        width="20"
                        height="20"
                    >
                        <path d="M8 8H28V28H8V8Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0_2047_39427)">
                        <path
                            className="social-secondary-color"
                            d="M23.75 8.93713H26.8171L20.1171 16.6143L28 27.0628H21.8286L16.9914 20.7271L11.4629 27.0628H8.39286L15.5586 18.8486L8 8.93856H14.3286L18.6943 14.7286L23.75 8.93713ZM22.6714 25.2228H24.3714L13.4 10.6814H11.5771L22.6714 25.2228Z"
                            fill="white"
                        />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_2047_39427">
                        <rect width="36" height="36" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default XIcon;
