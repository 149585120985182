import { Outlet, useNavigate } from "react-router-dom"
import { useAuthentication } from "../../../common/services/authentication.hook"
import { useEffect } from "react"

export const InternalDocumentsLayout = (props: {}) => {
    const navigate = useNavigate()
    const auth = useAuthentication()

    useEffect(() => {
        if (!auth.loading && !auth.isAllPCGIntUsers()) {
            navigate("/", { replace: true })
        }
    }, [auth.loading, auth.isAllPCGIntUsers()])

    return (
        <>
            <div>
                <Outlet />
            </div>
        </>
    )
}