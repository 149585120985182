import { Toast } from 'primereact/toast';
import { useRef } from 'react';

export const useNotifications = () => {
    const toast = useRef<Toast>(null);
    const toasterElement: React.JSX.Element = <Toast ref={toast}></Toast>;
    return {
            notify: (severity: "success" | "info" | "warn" | "error" | undefined, summary: string, detail: string) => {
                toast.current?.show({ severity: severity, summary: summary, detail: detail });
            },
            element: toasterElement,
        }
    }
