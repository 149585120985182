import React from "react"
import "./Breadcrumbs.scss"

export default function Breadcrumbs(props: any) {
    const breadcrumbs = props.items && props.items.length > 0 ? props.items.filter((item: any) => item.title && item.link) : []
    const className = props.className || ""
    if (breadcrumbs.length > 0) {
        return (
            <section className={"breadcrumbs " + className} data-testid={"breadcrumbs-wrapper"}>
                <ol itemScope itemType="https://schema.org/BreadcrumbList">
                    {breadcrumbs.map((item: any, index: any) => (
                        <React.Fragment key={"breadcrumb" + index}>
                            {index > 0 && <span>{"/"}</span>}
                            <li itemProp={"itemListElement"} itemScope itemType={"https://schema.org/ListItem"}>
                                <a itemProp={"item"} href={item.link} className={props.isInsight ? "isInsight" : ""} data-testid={"breadcrumbs-item"}>
                                    <span itemProp={"name"}>{item.title}</span>
                                </a>
                                <meta itemProp={"position"} content={index + 1} />
                            </li>
                        </React.Fragment>
                    ))}
                </ol>
            </section>
        )
    } else {
        return null
    }
}
