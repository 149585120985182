export class PCGUser {
  constructor(
    public auth_time: number = 0,
    public client_id: string = "",
    public groups: string[] = [],
    public exp: Date = new Date(),
    public iat: number = 0,
    public iss: string = "",
    public jti: string = "",
    public scope: string = "",
    public sub: string = "",
    public token_use: string = "",
    public username: string = "",
    public attributes: {
      email: string;
      given_name: string;
      family_name: string;
      middle_name: string;
    } = { email: "", given_name: "", family_name: "", middle_name: "" }
  ) {}

  public static fromJson(json: string): PCGUser {
    let obj = JSON.parse(json);

    obj.groups = obj["cognito:groups"];
    obj.name = "";
    return Object.assign(new PCGUser(), obj);
  }

  public setAttributes(attributes: any): void {
    this.attributes = attributes;
  }
}
