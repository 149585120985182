export function GetColor(colorName: string) {
  if (!colorName) {
    return "#ffea24";
  }

  switch (colorName) {
    case "azure":
      return "#b7e1f2";

    case "clr-tertiary-500":
      return "#ffea24";

    case "aws":
      return "#1f274b";

    case "clr-primary-500":
      return "#1f274b";

    case "clr-secondary-500":
      return "#b7e1f2";

    case "background":
      return "#f8f8f8";

    case "clr-quaternary-500":
      return "#43286f";

    case "clr-hero-insights":
      return "#ffea24";

    case "clr-white":
      return "#fff";

    case "clr-link":
      return "#43286f";

    case "clr-gray-100":
      return "#f5f5f5";

    default:
      return colorName;
  }
}
