import { useEffect, useState } from 'react';
import './SalesPeople.scss';
import { useNotifications } from '../../../common/hooks/notifications.hook';
import { useOwnerApi } from '../../../common/hooks/ownerApi.hook';
import { HubspotOwner } from '../../../common/services/s3-framework/models/HubspotOwner';
import { S3OwnersContext } from '../../../common/services/s3-framework/models/S3OwnersContext';
import { countryList } from '../../constants/Countries';

export const SalesPeople = (props: {}) => {
    const S3Context = new S3OwnersContext();
    const ownerApi = useOwnerApi();
    const [countries, setCountries] = useState<any>([]);
    const [goals, setGoals] = useState<any>([]);
    const notifications = useNotifications();

    const updateItemInArray = (array: any[], index: number, newValue: any) => {
        if (!array.map(a => a ? a.owner_id : '').includes(newValue.owner_id)) {
            return [...array, ...[newValue]]
        } else {
            array[array.findIndex((a: any) => a && a.owner_id === newValue.owner_id)] = newValue;
            return [...array];
        }
    }
    const saveChanges = async () => {
        //prepare goals for save 
        let goalsToSave: any = goals.filter((g: any) => g != null).map((g: any) => JSON.stringify(g)).join('\n');
        await S3Context.saveGoals(goalsToSave);
        let countriesToSave: any = countries.filter((g: any) => g != null).map((c: any) => JSON.stringify(c)).join('\n');
        await S3Context.saveCountries(countriesToSave);
        notifications.notify('success', `Got 'em!`, 'Your changes have been successfully saved.')
    }
    const refreshData = () => {
        S3Context.getOwners(ownerApi.onOwnersLoaded).then((owners) => {
        });
        S3Context.getCountries().then((owners) => {
            setCountries(owners as []);
        });
        S3Context.getGoals().then((owners) => {
            setGoals(owners as []);
        });
    }
    useEffect(() => {
       refreshData();
    }, []);

    return (
        <>
            {notifications.element}
            <div className="flex-container">
                <h1 className='title'>Salespeople</h1>

                <div className="salespeople-form-container">
                    <div className="salesperson-block salesperson-heading">
                        <div className="heading">Name</div>
                        <div className="heading">E-mail</div>
                        <div className="heading">Country</div>
                        <div className="heading">Goal</div>
                    </div>
                    {
                        ownerApi.owners.map((owner: HubspotOwner, index: number) => {
                            return (
                                <div className='salesperson-block' key={index}>
                                    <div className='name'>
                                        {owner.first_name + ' ' + owner.last_name}
                                    </div>
                                    <div className='email'>{owner.email}</div>
                                    <div className='country'>
                                        <div className={`select is-fullwidth`}>
                                            <select onChange={(e) => { setCountries(updateItemInArray(countries, index, { owner_country: e.target.value, owner_id: owner.id })) }} value={countries.find((c: any) => c?.owner_id === owner.id)?.owner_country ?? "Not set"} id="country" name="country">
                                                <option defaultValue={undefined}>Not set</option>
                                                {
                                                    countryList.map((c: any) => {
                                                        return <option key={c.code} value={c.name}>{c.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>

                                    </div>
                                    <div className='goal'>
                                        <input className='input' onChange={(e) => setGoals(updateItemInArray(goals, index, { owner_id: owner.id, yearly_goal_amount: e.target.value }))} value={goals.find((g: any) => g?.owner_id === owner.id)?.yearly_goal_amount ?? "Not set"} />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="btn-container">
                    <button className='button is-success save-btn' onClick={() => { saveChanges() }}><i className='pi pi-save'></i></button>
                    <button className='button is-warning reset-btn' onClick={() => { refreshData(); notifications.notify('info', 'Hang tight', 'Refreshing data...') }}><i className='pi pi-refresh'></i></button>
                </div>
            </div>
        </>
    )
};