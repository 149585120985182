import { useState } from "react"
import { useAuthentication } from "../../../services/authentication.hook"
import burgerIcon from "../../../assets/img/icons/burger.svg"
import closeIcon from "../../../assets/img/icons/close.svg"
import PCG from "../../../assets/img/logo-pcg/pcg-logo-horizontal.svg"

import "./MobileNavbar.scss"

export const MobileNavbar = (props: {}) => {
    const auth = useAuthentication()
    const [mobileMenu, setMobileMenu] = useState(false)

    const toggleMenu = () => {
        setMobileMenu(!mobileMenu)
    }
    const menuItems: { name: String; navHref: string }[] = [
        {
            name: "Case Studies",
            navHref: "/case-studies",
        },
        {
            name: "Documents",
            navHref: "/documents",
        },
        ...(auth.isAdmin() && !auth.loading
            ? [
                  {
                      name: "Admin",
                      navHref: "/admin",
                  },
              ]
            : []),
    ]
    return (
        <section className="mobile-navigation-wrapper">
            <div className="left-items">
                <button className={"burger-pcg"} onClick={toggleMenu}>
                    {mobileMenu ? <img src={closeIcon} alt="Close menu" width={36} height={36} /> : <img src={burgerIcon} alt="Open menu" width={36} height={36} />}
                </button>
                <a href={"/"} className="link-logo">
                    <img className={"logo-pcg "} src={PCG} alt={`PCG logo`} key={`PCG-logo`} />
                </a>
            </div>
            <div className="right-items">
                <div className="user">
                    {auth.user ? (
                        <div className="user-container">
                            <div className="user-image">
                                {auth.user.attributes.given_name.charAt(0)}
                                {auth.user.attributes.family_name.charAt(0)}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            {mobileMenu && (
                <div className={"mobile-nav__overlay"}>
                    <div className={"mobile-nav__wrapper-pcg"}>
                        {menuItems.map((item) => {
                            return (
                                <label>
                                    <a className={"mobile-nav__main-pcg"} href={item.navHref}>
                                        {item.name}
                                    </a>
                                </label>
                            )
                        })}
                    </div>
                </div>
            )}
        </section>
    )
}
