import React from "react";

const InstagramIcon: React.FC = () => {
    return (
        <div className="social" data-testid="social" aria-label="instagram">
            <svg
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    width="36"
                    height="36"
                    rx="18"
                    fill="#1F274B"
                    className="social-primary-color"
                />
                <g clipPath="url(#clip0_1779_16495)">
                    <path
                        className="social-secondary-color"
                        d="M13.8 8H22.2C25.4 8 28 10.6 28 13.8V22.2C28 23.7383 27.3889 25.2135 26.3012 26.3012C25.2135 27.3889 23.7383 28 22.2 28H13.8C10.6 28 8 25.4 8 22.2V13.8C8 12.2617 8.61107 10.7865 9.69878 9.69878C10.7865 8.61107 12.2617 8 13.8 8ZM13.6 10C12.6452 10 11.7295 10.3793 11.0544 11.0544C10.3793 11.7295 10 12.6452 10 13.6V22.4C10 24.39 11.61 26 13.6 26H22.4C23.3548 26 24.2705 25.6207 24.9456 24.9456C25.6207 24.2705 26 23.3548 26 22.4V13.6C26 11.61 24.39 10 22.4 10H13.6ZM23.25 11.5C23.5815 11.5 23.8995 11.6317 24.1339 11.8661C24.3683 12.1005 24.5 12.4185 24.5 12.75C24.5 13.0815 24.3683 13.3995 24.1339 13.6339C23.8995 13.8683 23.5815 14 23.25 14C22.9185 14 22.6005 13.8683 22.3661 13.6339C22.1317 13.3995 22 13.0815 22 12.75C22 12.4185 22.1317 12.1005 22.3661 11.8661C22.6005 11.6317 22.9185 11.5 23.25 11.5ZM18 13C19.3261 13 20.5979 13.5268 21.5355 14.4645C22.4732 15.4021 23 16.6739 23 18C23 19.3261 22.4732 20.5979 21.5355 21.5355C20.5979 22.4732 19.3261 23 18 23C16.6739 23 15.4021 22.4732 14.4645 21.5355C13.5268 20.5979 13 19.3261 13 18C13 16.6739 13.5268 15.4021 14.4645 14.4645C15.4021 13.5268 16.6739 13 18 13ZM18 15C17.2044 15 16.4413 15.3161 15.8787 15.8787C15.3161 16.4413 15 17.2044 15 18C15 18.7956 15.3161 19.5587 15.8787 20.1213C16.4413 20.6839 17.2044 21 18 21C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18C21 17.2044 20.6839 16.4413 20.1213 15.8787C19.5587 15.3161 18.7956 15 18 15Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1779_16495">
                        <rect
                            className="social-secondary-color"
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(8 8)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default InstagramIcon;
