import React from "react";

const FacebookIcon: React.FC = () => {
    return (
        <div className="social" data-testid="social" aria-label="facebook">
            <svg
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_1779_16493)">
                    <rect
                        width="36"
                        height="36"
                        rx="18"
                        fill="#1F274B"
                        className="social-primary-color"
                    />
                    <path
                        className="social-secondary-color"
                        d="M22.4212 24.2637V36.0714H17.8424V24.2699H14V19.9245H17.8424V16.7254C17.8424 13.019 20.1706 11.0001 23.5707 11.0001C24.7166 10.9969 25.8606 11.052 27 11.1652V15.0434H24.6594C22.8068 15.0434 22.4468 15.8923 22.4468 17.1491V19.9183H26.8751L26.2988 24.2637H22.4212Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1779_16493">
                        <rect
                            className="social-secondary-color"
                            width="36"
                            height="36"
                            rx="18"
                            fill="white"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default FacebookIcon;
