import React, { FC, ReactNode, useState } from "react"
import "./Table.scss"

interface CustomDataTableProps {
    value: any[]
    rows?: number
    scrollable?: boolean
    scrollHeight?: string
    showGridlines?: boolean
    stripedRows?: boolean
    sortIcon?: (options: any) => JSX.Element
    children: ReactNode
}

const CustomDataTable: FC<CustomDataTableProps> = ({ value, rows = 10, scrollable = false, scrollHeight, showGridlines = false, stripedRows = false, sortIcon, children }) => {
    const [sortField, setSortField] = useState<string | null>(null)
    const [sortOrder, setSortOrder] = useState<number>(1)

    const handleSort = (field: string) => {
        if (sortField === field) {
            setSortOrder(sortOrder * -1)
        } else {
            setSortField(field)
            setSortOrder(1)
        }
    }

    const sortedValue = [...value].sort((a, b) => {
        if (!sortField) return 0
        const aValue = a[sortField]
        const bValue = b[sortField]
        if (aValue < bValue) return -1 * sortOrder
        if (aValue > bValue) return 1 * sortOrder
        return 0
    })

    return (
        <section className={`custom-datatable ${scrollable ? "scrollable" : ""} ${showGridlines ? "gridlines" : ""} ${stripedRows ? "striped" : ""}`} style={{ maxHeight: scrollHeight, overflowX: "auto", overflowY: "auto" }}>
            <table className="custom-datatable__table">
                <thead>
                    <tr>
                        {React.Children.map(children, (child) => {
                            const element = child as React.ReactElement<any>
                            if (element.props.sortable) {
                                return React.cloneElement(element, {
                                    header: true,
                                    onClick: () => handleSort(element.props.field),
                                    sortOrder: sortField === element.props.field ? sortOrder : 0,
                                    sortIcon,
                                })
                            }
                            return React.cloneElement(element, { header: true })
                        })}
                    </tr>
                </thead>
                <tbody>
                    {sortedValue.slice(0, rows).map((item, rowIndex) => (
                        <tr key={rowIndex}>{React.Children.map(children, (child) => React.cloneElement(child as React.ReactElement<any>, { data: item }))}</tr>
                    ))}
                </tbody>
            </table>
        </section>
    )
}

export default CustomDataTable
