import React from "react"
import "./Button.scss"
import { GetColor } from "../../services/ColorService"
import ArrowDecoration from "../decorations/element-decoration/ArrowDecoration"
//Buttons can be: primary, secondary and icon.

//Primary button - button with animation in the center on hover
//Primary button can be large and small:
//Small button should have classes: .btn__primary .btn__primary--small
//Large button should have classes: .btn__primary .btn__primary--large

//Icon button - button with icon inside
//Icon button can be next, back and circle:
//Next button should have class: .btn__icon--next
//Back button should have class: .btn__icon--back
//Circle button should have class: .btm__icon--circle

export default function Button({ hasArrow = false, ...props }) {
    let fillColor = GetColor(props.color)
    let className = props.className ? props.className : ""
    let textColorClass = `btn_text--${props.textColor || props.color || "clr-tertiary-500"}`

    const buttonType = ["submit", "reset", "button"]
    const checkButtonType = buttonType.includes(props.type) ? props.type : "button"

    if (props.href) {
        let targetAttr
        if (props.linkType === "external" || (props.linkType === "internal" && props.newTab === true)) {
            targetAttr = "_blank"
        }
        return (
            <a
                type={checkButtonType}
                className={`${className} ${textColorClass}`}
                style={{ backgroundColor: fillColor }}
                href={props.href}
                target={targetAttr}
                data-offer-type={props.seoOfferData ? props.seoOfferData["data-offer-type"] : null}
                data-offer-title={props.seoOfferData ? props.seoOfferData["data-offer-title"] : null}
                data-offer-price={props.seoOfferData ? props.seoOfferData["data-offer-price"] : null}
                data-offer-currency={props.seoOfferData ? props.seoOfferData["data-offer-currency"] : null}
                data-offer-duration={props.seoOfferData ? props.seoOfferData["data-offer-duration"] : null}
            >
                <span className={"before"} />
                {props.text}
                {hasArrow && <ArrowDecoration className={"btn-arrow"} />}
            </a>
        )
    } else if (props.onClick) {
        return (
            <button
                type={checkButtonType}
                className={`${className} ${textColorClass}`}
                style={{ backgroundColor: fillColor }}
                onClick={() => props.onClick(props.data)}
                data-offer-type={props.seoOfferData ? props.seoOfferData["data-offer-type"] : null}
                data-offer-title={props.seoOfferData ? props.seoOfferData["data-offer-title"] : null}
                data-offer-price={props.seoOfferData ? props.seoOfferData["data-offer-price"] : null}
                data-offer-currency={props.seoOfferData ? props.seoOfferData["data-offer-currency"] : null}
                data-offer-duration={props.seoOfferData ? props.seoOfferData["data-offer-duration"] : null}
            >
                <span className={"before"} />
                {props.text}
                {hasArrow && <ArrowDecoration className={"btn-arrow"} />}
            </button>
        )
    } else {
        return (
            <button
                type={checkButtonType}
                className={`${className} ${textColorClass}`}
                style={{ backgroundColor: fillColor }}
                data-offer-type={props.seoOfferData ? props.seoOfferData["data-offer-type"] : null}
                data-offer-title={props.seoOfferData ? props.seoOfferData["data-offer-title"] : null}
                data-offer-price={props.seoOfferData ? props.seoOfferData["data-offer-price"] : null}
                data-offer-currency={props.seoOfferData ? props.seoOfferData["data-offer-currency"] : null}
                data-offer-duration={props.seoOfferData ? props.seoOfferData["data-offer-duration"] : null}
            >
                <span className={"before"} />
                {props.text}
                {hasArrow && <ArrowDecoration className={"btn-arrow"} />}
            </button>
        )
    }
}
