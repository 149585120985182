import { useState } from "react";
import { HubspotOwner } from "../services/s3-framework/models/HubspotOwner";

export const useOwnerApi = () => {
    const [owners, setOwners] = useState<any>([]);
    const allowedTeams = ['11900438', '11902714', '41017761'];
    const onOwnersLoaded = (data: any) => {
        let distinct_owners: HubspotOwner[] = [];

        data.forEach((owner: HubspotOwner) => {
            if ((!distinct_owners.some((a: HubspotOwner) => a.id === owner.id)) && allowedTeams.includes(owner.team_id)) {
                distinct_owners.push(owner);
            }
        });

        setOwners(distinct_owners);
    }

    return {
        owners,
        onOwnersLoaded
    };
}