import React from "react"
import "./Tag.scss"

function Tag(props: any) {
    const classes = `tag ${props.mobile ? "tag-mobile" : ""} ${props.type ? `tag-${props.type}` : ""} ${props.fromHero ? "tag-hero" : ""} ${props.featured ? "featured" : ""}`
    if (props.href) {
        return (
            <a key={props.key} className={classes} href={props.href} data-testid={"insight-tag"}>
                {props.title}
            </a>
        )
    } else {
        return (
            <div key={props.key} className={classes} data-testid={"insight-tag"}>
                {props.title}
            </div>
        )
    }
}

export default Tag
