import React, { useState, useRef, useImperativeHandle, forwardRef } from "react"
import searchIcon from "../../assets/img/icons/search.svg"
import "./SearchField.scss"

interface SearchFieldProps {
    onClick: (search_term: string) => void
    placeholder?: string
}

export type SearchFieldRef = {
    setSearchField: (searchValue: string) => void
}
const SearchField = forwardRef(function SearchField(props: SearchFieldProps, ref) {
    const inputRef = useRef(null)
    const [inputVal, setInputVal] = useState("")

    useImperativeHandle(
        ref,
        () => {
            return {
                setSearchField(searchValue: string) {
                    setInputVal(searchValue)
                },
            }
        },
        []
    )

    const HandleChange = (e: any) => {
        const value = e.target?.value.replace(/[/\\#^+()$£~%.,_\[=`":;*?<>|{}!@§±\]]/gi, "").replace(/\s+/, " ")
        setInputVal(value)
        props.onClick(value)
    }

    return (
        <div>
            <div className="modal-search__main">
                <img src={searchIcon} alt="Search Icon" className="searchIcon-modal" />
                <input id="search" ref={inputRef} placeholder={props.placeholder ? props.placeholder : "Search table..."} type="search" value={inputVal} onChange={(e) => HandleChange(e)} className="searchfield-modal" inputMode={"search"} />
            </div>
        </div>
    )
})

export default SearchField
