import { useInternalPortalAuthProvider } from "../provider/internal-auth-provider.hook";
import "./Login.scss";
export const Login = () => {
  let authProvider = useInternalPortalAuthProvider();

  return (
    <div>
      <div className="login-container">
        <h1 className="login-title">Hi there</h1>
        <div className="login-btn-container">
          <a
            href={authProvider.ssoLoginUrl}
            className="button is-link is-outlined"
          >
            Use SSO
          </a>
        </div>
      </div>
    </div>
  );
};
