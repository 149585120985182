export function GetPageByKey(key: any, allPages: Array<any>) {
    let currentPage = null
    const filteredPage = allPages.filter((page) => page.key === key)
    if (filteredPage && filteredPage.length > 0) {
        currentPage = filteredPage[0]
    }
    return currentPage
}

export function GetSanityLink(link: any) {
    if (link && link.type === "external" && link.url) {
        return link.url
    }

    if (link && link.type === "in_page" && link.elementId) {
        return link.elementId
    }
    if (!(link && link.page)) {
        return null
    }

    return `${link.page.documentId.current}`
}

export function ScrollToElement(elementId: any, inline: any, block: any, offset: any) {
    const element = document.getElementById(elementId)
    if (element) {
        if (offset) {
            const elementPosition = element.getBoundingClientRect().top
            const offsetPosition = elementPosition + window.pageYOffset - offset
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            })
        } else {
            element.scrollIntoView({
                behavior: "smooth",
                inline: inline,
                block: block,
            })
        }
    }
}

export function ScrollToTocElement(index: any, element: any) {
    const headings = document.getElementsByTagName(element)
    const filteredHeadings = Array.from(headings).filter((el) => el.className.trim() !== "dot-decoration")
    const elementPosition = filteredHeadings[index].getBoundingClientRect().top
    const offsetPosition = elementPosition + window.scrollY - 90
    window.scrollTo({ top: Math.round(offsetPosition), behavior: "smooth" })
}

export function GetISOLang(lang: string) {
    if (lang === "se") {
        return "sv"
    }
    if (lang === "ch") {
        return "de"
    }
    if (lang === "gr") {
        return "el"
    }
    return lang
}

export function GetAltText(data: any, key: any) {
    if (data.customHeroImage) {
        if (data.customHeroImage.altText) {
            return data.customHeroImage.altText
        }
        if (data.customHeroImage.image && data.customHeroImage.image.asset && data.customHeroImage.image.asset.altText) {
            return data.customHeroImage.image.asset.altText
        }
        if (data.customHeroImage.image && data.customHeroImage.image.asset && data.customHeroImage.image.asset.fileName) {
            return data.customHeroImage.image.asset.fileName
        }
    } else if (data.node) {
        if (data.node.altText) {
            return data.node.altText
        }
        if (data.node.image && data.node.image.asset && data.node.image.asset.altText) {
            return data.node.image.asset.altText
        }
        if (data.node.image && data.node.image.asset && data.node.image.asset.fileName) {
            return data.node.image.asset.fileName
        }
    } else {
        return "image-" + key
    }
}

export function GetRawKey(lang: string) {
    if (lang === "en") {
        return "_rawEn"
    }
    if (lang === "se") {
        return "_rawSe"
    }
    if (lang === "ch") {
        return "_rawCh"
    }
    if (lang === "gr") {
        return "_rawGr"
    }
    return "_rawEn"
}

export function GetPageUrl(key: string, pages: any, lang: string) {
    const page = pages.filter((page: any) => page.key === key)
    if (page && page.length > 0) {
        return page[0].slugs[`slug_${lang}`] ? page[0].slugs[`slug_${lang}`].current : null
    }
    return null
}
