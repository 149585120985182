import React, { useEffect, useState, useRef } from "react"
import "./multi-options-filter.scss"
import { useSanityApi } from "../../../website-integration/hooks/sanityApi.hook"
import Modal from "../modal/modal"
import Button from "../button/Button"
import close from "../../assets/img/icons/close.svg"
import CustomCheckbox from "../custom-checkbox/custom-checkbox"
import SearchField, { SearchFieldRef } from "../../../common/components/SearchField/SearchField"

interface Option {
    key: string
    label: string
    value: string
}

interface MultiOptionFilterProps {
    options: Option[]
    activeValues?: string[] | undefined
    filter_category_name: string
    filterType: "industries" | "languages" | "countries" | "owners" | "types" | "departments" | "applicables"
    onChange: (tags: string[]) => void
    className?: string
    color?: string
    resetAllFilters?: () => void
}

export default function MultiOptionFilter(props: MultiOptionFilterProps) {
    const sanityApi = useSanityApi()
    const [allOptions, setAllOptions] = useState<Option[]>(props.options)
    const [activeTags, setActiveTags] = useState<string[]>(props.activeValues ? props.activeValues : [])
    const [isMobile, setIsMobile] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
    const ref = useRef<SearchFieldRef>(null)

    const handleMobile = () => {
        setIsMobile(window.innerWidth < 1024)
    }
    useEffect(() => {
        handleMobile()
        window.addEventListener("resize", handleMobile)
        return () => {
            window.removeEventListener("resize", handleMobile)
        }
    }, [])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const filterButton = document.getElementById("add-filters-button" + props.filter_category_name)
            if (dropdownOpen && !filterButton?.contains(event.target as Node)) {
                const dropdown = document.getElementById("multi_filter_dropdown_" + props.filter_category_name)
                if (dropdown && !dropdown.contains(event.target as Node)) {
                    closeDropdown()
                }
            }
        }

        if (dropdownOpen) {
            document.addEventListener("click", handleClickOutside)
        }
        return () => {
            document.removeEventListener("click", handleClickOutside)
        }
    }, [dropdownOpen])

    useEffect(() => {
        if (modalOpen) {
            document.body.style.overflowY = "hidden"
        } else {
            document.body.style.overflowY = "unset"
        }
    }, [modalOpen])

    useEffect(() => {
        if (modalOpen && !isMobile) {
            setModalOpen(false)
            SubmitTags()
            setDropdownOpen(true)
            MultyFilterDropdown(props.filter_category_name)
        } else if (dropdownOpen && isMobile) {
            closeDropdown()
            setModalOpen(true)
        }
    }, [isMobile])

    useEffect(() => {
        if (props.activeValues) {
            setActiveTags(props.activeValues)
        }
    }, [props.activeValues])

    const MultyFilterDropdown = (filterCategoryName: string, disable: boolean = false, isCloseButton: boolean = false) => {
        const dropdown = document.getElementById("multi_filter_dropdown_" + filterCategoryName)
        const filterButton = document.getElementById("add-filters-button" + filterCategoryName)

        if (!dropdown || !filterButton) {
            return
        }

        const currentlyOpen = dropdown.classList.contains("multi_filter_dropdown__show")

        if (!disable) {
            if (currentlyOpen) {
                dropdown.classList.remove("multi_filter_dropdown__show")
                dropdown.classList.add("multi_filter_dropdown__hide")
                filterButton.classList.remove("add_filters_underline")
            } else {
                dropdown.classList.remove("multi_filter_dropdown__hide")
                dropdown.classList.add("multi_filter_dropdown__show")
                filterButton.classList.add("add_filters_underline")
                ;["industries", "languages", "countries", "owners"].forEach((category) => {
                    if (category !== filterCategoryName) {
                        MultyFilterDropdown(category, true)
                    }
                })
            }
        } else {
            dropdown.classList.remove("multi_filter_dropdown__show")
            dropdown.classList.add("multi_filter_dropdown__hide")
            filterButton.classList.remove("add_filters_underline")
        }
    }

    const ChangeTags = (tag: string, fromModal: boolean) => {
        let updatedTags: string[] = []

        if (activeTags.includes(tag)) {
            updatedTags = activeTags.filter((item) => item !== tag)
        } else {
            updatedTags = [...activeTags, tag]
        }

        setActiveTags(updatedTags)
        if (!fromModal) {
            props.onChange(updatedTags)
        }
    }

    const SubmitTags = () => {
        props.onChange(activeTags.length ? activeTags : [])
        setModalOpen(false)
        closeDropdown()
    }

    const OnModalClose = () => {
        setModalOpen(false)
        SubmitTags()
    }

    let searchString = ""
    const OnSearchInput = (searchValue: string) => {
        searchString = searchValue.toLowerCase()
        SearchOptions()
    }

    function SearchOptions() {
        let optionsAfterSearch = props.options.filter((option) => option.value.toLowerCase().includes(searchString.toLowerCase()))
        setAllOptions(optionsAfterSearch)
    }

    const closeDropdown = () => {
        setDropdownOpen(false)
        const dropdown = document.getElementById("multi_filter_dropdown_" + props.filter_category_name)

        if (dropdown) {
            dropdown.classList.remove("multi_filter_dropdown__show")
            dropdown.classList.add("multi_filter_dropdown__hide")
        }

        const filterButton = document.getElementById("add-filters-button" + props.filter_category_name)
        if (filterButton) {
            filterButton.classList.remove("add_filters_underline")
        }
    }

    return (
        <div className="multi-filters">
            <button
                onClick={() => {
                    if (isMobile) {
                        setModalOpen(true)
                    } else {
                        MultyFilterDropdown(props.filter_category_name, false)
                        setDropdownOpen(!dropdownOpen)
                    }
                }}
                className="add_filters"
                id={"add-filters-button" + props.filter_category_name}
            >
                {props.filter_category_name}
                <span className="add_filters--counter">
                    (<span className="number">{activeTags[0] === "all" ? 0 : activeTags.length}</span>)
                </span>
            </button>
            <div id={"multi_filter_dropdown_" + props.filter_category_name} className="multi_filter_dropdown__hide">
                <div className="multi_filter_dropdown__sticky">
                    <div className="multi_filter_dropdown__title">
                        <p>Filter by {props.filter_category_name}</p>
                        <Button className="btn__icon--circle__insights--clear_filters btn-close" color={"white"} onClick={closeDropdown} />
                    </div>
                    <SearchField placeholder={`Search ${props.filter_category_name}...`} onClick={OnSearchInput} ref={ref}></SearchField>
                    <div className="multi_filter_dropdown__reset-div">
                        All {props.filter_category_name}
                        {activeTags.length > 0 && <Button className="btn__filter__insights reset" text={"Reset"} onClick={props.resetAllFilters} />}
                    </div>
                    <div className="multi_filter_dropdown__filtersList">
                        {allOptions && allOptions.length > 0 ? (
                            allOptions.map((option) => <CustomCheckbox id={option.key} name="terms" value={option.key} checked={activeTags.includes(option.key)} onChange={() => ChangeTags(option.key, false)} label={option.value} />)
                        ) : (
                            <div className="multi_filter_dropdown--no-results">
                                <p>No results</p>
                                <p>Do another search</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {modalOpen && (
                <Modal title={`Filter by ${props.filter_category_name}`} closeButtonClassName="btn__icon--circle__insights btn-close" closeButtonColor="white" isOpen={modalOpen} onClose={OnModalClose} contentLabel="Filter Modal" SubmitTags={SubmitTags}>
                    <div>
                        <SearchField placeholder={`Search ${props.filter_category_name}...`} onClick={OnSearchInput} ref={ref}></SearchField>
                        <div className="multi_filter_dropdown__reset-div">
                            All {props.filter_category_name}
                            {activeTags.length > 0 && <Button className="btn__filter__insights reset" text={"Reset"} onClick={props.resetAllFilters} />}
                        </div>
                        <div className="modal-options">
                            {allOptions && allOptions.length > 0 ? (
                                allOptions.map((option) => <CustomCheckbox id={option.key} name="terms" value={option.key} checked={activeTags.includes(option.key)} onChange={() => ChangeTags(option.key, true)} label={option.value} />)
                            ) : (
                                <div className="multi_filter_dropdown--no-results">
                                    <p>No results</p>
                                    <p>Do another search</p>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    )
}
