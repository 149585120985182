import HeadingTag from "../../../../common/components/HeadingTag/HeadingTag"
import { VersionHistoryCard } from "../../cards/version-history-card"

import "./version-history.scss"

export const VersionHistorySection = (props: {
    document: {
        listVersionHistory: any
    }
}) => {
    const { document } = props

    return (
        <section className="version-history">
            <div className="version-history-wrapper">
                <HeadingTag type="h2">Version History</HeadingTag>
                <div className="versions">
                    {document.listVersionHistory &&
                        document.listVersionHistory
                            ?.sort((a: any, b: any) => b.version - a.version)
                            .map((version: any, i: number) => {
                                return <VersionHistoryCard version={version} key={i} />
                            })}
                </div>
            </div>
        </section>
    )
}
