import React from "react";
import "./custom-checkbox.scss";

interface CustomCheckboxProps {
  id?: string;
  name?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  label?: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  id,
  name,
  value = "",
  onChange,
  checked = false,
  label,
}) => {
  return (
    <div
      className={`checkbox-container ${
        checked ? "checkbox-container--checked" : ""
      }`}
    >
      <input
        type="checkbox"
        id={id || value}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
        className="checkbox__input--hideDefault"
      />
      <label htmlFor={id || value} className="checkbox__wrapper">
        <span
          className={`checkbox__checkmark ${
            checked ? "checkbox__checkmark--checked" : ""
          }`}
        ></span>
        <span className="checkbox__item">{label}</span>
      </label>
    </div>
  );
};

export default CustomCheckbox;
