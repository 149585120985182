import React from "react"
/**
 * Wrapper component for headings
 * @param {{
 *  type: ('display'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6'),
 *  dotDecorationColor: String,
 *  className: String,
 *  style: React.CSSProperties,
 *  showDotDecoration: Boolean?
 * }} param0
 * @returns {JSX.Element}
 */

// interface HeadingTagProps {
//   type: string; // Replace 'string' with the appropriate type for 'type'
//   dotDecorationColor: string; // Assuming dotDecorationColor is a string
//   showDotDecoration?: boolean;
//   className?: string;
//   style?: React.CSSProperties;
//   children?: React.ReactNode;
// }
const HeadingTag: React.FC<any> = ({ type, dotDecorationColor, showDotDecoration = true, className, style, children, ...props }) => {
    if (!type) {
        throw new Error("'type' is not provided")
    }

    const newStyle = style || {}
    if (dotDecorationColor) {
        newStyle["--title-decoration"] = dotDecorationColor
    }

    const newClassName = `${showDotDecoration ? "dot-decoration " : ""}` + `${type === "display" ? "display " : ""}` + `${className ?? ""}`

    switch (type) {
        case "display":
        case "h1":
            return (
                <h1 className={newClassName} style={newStyle} {...props}>
                    {children}
                </h1>
            )

        case "h2":
            return (
                <h2 className={newClassName} style={newStyle} {...props}>
                    {children}
                </h2>
            )

        case "h3":
            return (
                <h3 className={newClassName} style={newStyle} {...props}>
                    {children}
                </h3>
            )

        case "h4":
            return (
                <h4 className={newClassName} style={newStyle} {...props}>
                    {children}
                </h4>
            )

        case "h5":
            return (
                <h5 className={newClassName} style={newStyle} {...props}>
                    {children}
                </h5>
            )

        case "h6":
            return (
                <h6 className={newClassName} style={newStyle} {...props}>
                    {children}
                </h6>
            )

        default:
            throw new Error("Invalid 'type' provided")
    }
}

export default HeadingTag
