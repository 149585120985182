export class HubspotOwner {
    constructor(
        public id: string = "",
        public email: string = "",
        public first_name: string = "",
        public last_name: string = "",
        public team_id: string = ""
    ) {

    }
}