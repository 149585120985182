import React from "react"
import "./modal.scss"
import Button from "../button/Button"
import closeButton from "../../assets/img/icons/delete.svg"
import HeadingTag from "../HeadingTag/HeadingTag"

const Modal = (props: any) => {
    const GetModalDialogClass = () => {
        if (props.formSubmitState) {
            if (props.formSubmitState === "init") {
                return "modal__dialog"
            }
            if (props.formSubmitState === "success") {
                return "modal__dialog--success"
            }
            if (props.formSubmitState === "error") {
                return "modal__dialog--error"
            }
            return "modal__dialog"
        } else {
            return "modal__dialog"
        }
    }

    const GetModalContentClass = () => {
        if (props.formSubmitState) {
            if (props.formSubmitState === "init") {
                return "modal__content"
            }
            if (props.formSubmitState === "success") {
                return "modal__content--success"
            }
            if (props.formSubmitState === "error") {
                return "modal__content--error"
            }
            return "modal__content"
        } else {
            return "modal__content"
        }
    }

    const titleText = props.headerTitle || props.title

    return (
        <div className={`modal ${props.className ?? ""}`}>
            <div className={GetModalDialogClass()}>
                <div className={GetModalContentClass()}>
                    <div className="modal__content__container">
                        {(props.headerTitle || props.title) && (
                            <div className={"modal__header"}>
                                <div className={"modal__header__title"}>
                                    <HeadingTag type="h3">{titleText}</HeadingTag>
                                </div>
                                <span onClick={props.onClose} className="close-button">
                                    <img src={closeButton} alt={"close-button"} className="message__header__button" />
                                </span>
                            </div>
                        )}
                        {props.children && <React.Fragment>{props.children}</React.Fragment>}
                    </div>

                    <div className={"modal__action-buttons"}>
                        <Button hasArrow={true} text={"filter results"} className="btn__primary btn__primary--large btn-filter btn_text--clr-tertiary-500 filter" onClick={props.SubmitTags} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal
