import { INDUSTRY_OPTIONS } from "../constants/industry"
import { parseContent } from "./customer_table_render_helpers"

export const filterBySearchFields = (companies: any[], searchField: string) => {
    if (searchField.length >= 3) {
        return companies.filter(
            (company: any) =>
                company?.studyTitle?.toLowerCase().includes(searchField.toLowerCase()) ||
                company?.name?.toLowerCase().includes(searchField.toLowerCase()) ||
                company?.domain?.toLowerCase().includes(searchField.toLowerCase()) ||
                company?.country?.toLowerCase().includes(searchField.toLowerCase()) ||
                company?.owner_name?.toLowerCase().includes(searchField.toLowerCase()) ||
                company?.industry?.toLowerCase().includes(searchField.toLowerCase()) ||
                company?.hyperscaler?.toLowerCase().includes(searchField.toLowerCase()) ||
                INDUSTRY_OPTIONS[company?.industry as string]?.toLowerCase().includes(searchField.toLowerCase())
        )
    }

    return companies
}

export const filterByCountry = (companies: any[], selectedCountries: any[]) => {
    if (selectedCountries.length > 0) {
        return companies.filter((company: any) => selectedCountries.find((selectedCountry) => selectedCountry == company.country))
    }
    return companies
}

export const filterByIndustry = (companies: any[], selectedIndustries: any[]) => {
    if (selectedIndustries.length > 0) {
        return companies.filter((company: any) => selectedIndustries.find((selectedIndustry) => selectedIndustry == parseContent(INDUSTRY_OPTIONS[company.industry as string])))
    }
    return companies
}

export const filterByOwner = (companies: any[], selectedOwners: any[]) => {
    if (selectedOwners.length > 0) {
        return companies.filter((company: any) => selectedOwners.find((selectedOwner) => selectedOwner == company.owner_name))
    }
    return companies
}

export const filterByLanguage = (companies: string[], selectedLanguages: string[]) => {
    if (selectedLanguages.length > 0) {
        return companies.filter((company: any) => selectedLanguages.find((selectedLanguage) => company.languages.includes(selectedLanguage)))
    }
    return companies
}
