import { HubspotOwner } from "../../common/services/s3-framework/models/HubspotOwner"
import { ParquetCompanyPayload } from "../../common/services/s3-framework/models/ParquetCompanyPayload"
import { HubspotCompany } from "../models/HubspotCompany"
import { Client, CaseStudy, Service } from "../types"

export const parseContent = (content: string) => {
    return content ? content : "None specified"
}

export const filterAndMergeHubspotCompaniesWithStudies = (companies: ParquetCompanyPayload[], _owners: any, companyStudies: any[]) => {
    const languageMap: { [key: string]: string } = {
        ch: "German",
        en: "English",
        se: "Swedish",
        gr: "Greek",
    }
    return companies
        .filter((company: ParquetCompanyPayload) => companyStudies.some((sanityCompany: any) => sanityCompany.name && company.name && (sanityCompany.name.includes(company.name) || company.name?.includes(sanityCompany.name) || (sanityCompany.companyId != null && (company.hs_object_id?.includes(sanityCompany.companyId.toString()) || sanityCompany.companyId?.toString().includes(company.hs_object_id)))) && company.lifecyclestage === "customer"))
        .map((filteredCompany: ParquetCompanyPayload) => {
            let owner = _owners.find((owner: HubspotOwner) => owner.id === filteredCompany.hubspot_owner_id)
            let useCase = companyStudies.find((sanityCompany: any) => sanityCompany.name?.includes(filteredCompany.name) || filteredCompany.name?.includes(sanityCompany.name) || (sanityCompany.companyId != null && (filteredCompany.hs_object_id?.includes(sanityCompany.companyId.toString()) || sanityCompany.companyId.toString().includes(filteredCompany.hs_object_id))))
            let isMatch = Boolean(companyStudies.find((sanityCompany) => sanityCompany.companyId != null && (filteredCompany.hs_object_id?.includes(sanityCompany.companyId?.toString()) || sanityCompany.companyId?.toString().includes(filteredCompany.hs_object_id))))
            let languages = useCase.languages.map((code: string) => languageMap[code] || code)
            return new HubspotCompany(filteredCompany.name, filteredCompany.hubspot_owner_id, filteredCompany.country, filteredCompany.domain, filteredCompany.industry, owner ? `${owner.first_name} ${owner.last_name}` : "Not set", filteredCompany.hs_object_id, owner ? owner.email : "", useCase ? useCase.service : "Agnostic", useCase ? useCase.studyTitle : "", isMatch, languages)
        })
}

// export const processAndExportCaseStudyData = (caseStudies: any) => {
//     let caseStudiesByClientId: { [index: string]: { slugs: string, studyTitle: string, serviceId: string, languages: {}[], isDraft: boolean } } = {};
//     caseStudies
//         .forEach((caseStudy: any) => {
//             if (caseStudy.client?._ref != null) {
//                 caseStudiesByClientId[caseStudy.client?._ref] = {
//                     slugs: caseStudy.slugs,
//                     studyTitle: caseStudy.title.en,
//                     serviceId: caseStudy.primary_service?._ref,
//                     languages: [
//                         ...['en'],
//                         ...Object.entries(caseStudy.localeAvailability).map(([key, value]) => value ? key : null)
//                             .filter((c: any) => c != null && c !== '_type') as []
//                     ],
//                     isDraft: caseStudy._id.startsWith('drafts.')
//                 }
//             }
//         });
//     return caseStudiesByClientId;
// }

export const mergeClientAndStudyData = (clients: Client[], caseStudiesByClientId: { [key: string]: CaseStudy }, services: Service[]) => {
    const servicesMap = services.reduce((acc: { [key: string]: string }, service: Service) => {
        acc[service._id] = service.title
        return acc
    }, {})

    return clients.map((client) => ({
        name: client.title,
        slug: caseStudiesByClientId[client._id]?.slugs?.slug_en?.current || null,
        studyTitle: caseStudiesByClientId[client._id].studyTitle,
        companyId: client.companyId,
        service: servicesMap[caseStudiesByClientId[client._id].serviceId] || "Agnostic",
        languages: caseStudiesByClientId[client._id].languages,
        slugs: caseStudiesByClientId[client._id].slugs,
        isDraft: caseStudiesByClientId[client._id].isDraft,
    }))
}

export const determineLocale = (language: string) => {
    switch (language) {
        case "en":
            return "/insights"
        case "ch":
            return "/de/insights"
        case "se":
            return "/sv/insikter"
        case "gr":
            return "/el/insights"
    }
}

export const normalizeValues = (values: any[]) => {
    return values.map((value) => normalizeString(value)).join("_")
}

export const normalizeString = (str: string) => {
    const normalized = str
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .replace(/\s+/g, "-")
    return normalized
}
