import React from "react";

const LinkedinIcon: React.FC = () => {
    return (
        <div className="social" data-testid="social" aria-label="linkedin">
            <svg
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    width="36"
                    height="36"
                    rx="18"
                    fill="#1F274B"
                    className="social-primary-color"
                />
                <path
                    className="social-secondary-color"
                    d="M11.2914 9C9.85915 9 9 9.86798 9 11.0238C9 12.1796 9.85926 13.0442 11.1478 13.0442C12.58 13.0442 13.4391 12.1796 13.4391 11.0238C13.4391 9.86798 12.5799 9 11.2914 9ZM9.11424 15.2062V27H13.3314V15.2062H9.11424ZM15.4387 15.2062V27H19.6559V20.5536C19.6559 18.6345 21.0139 18.3587 21.4217 18.3587C21.8295 18.3587 22.9199 18.771 22.9199 20.5536V27H27V20.5536C27 16.852 25.3685 15.2062 23.3279 15.2062C21.2874 15.2062 20.199 15.8912 19.6559 16.8516V15.2062H15.4387Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};

export default LinkedinIcon;
