import React from "react";

const YoutubeIcon: React.FC = () => {
    return (
        <div className="social" data-testid="social" aria-label="youtube">
            <svg
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_2047_39420)">
                    <path
                        d="M36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18Z"
                        fill="#1F274B"
                        className="social-primary-color"
                    />
                    <path
                        className="social-secondary-color"
                        d="M16 21L21.19 18L16 15V21ZM27.56 13.17C27.69 13.64 27.78 14.27 27.84 15.07C27.91 15.87 27.94 16.56 27.94 17.16L28 18C28 20.19 27.84 21.8 27.56 22.83C27.31 23.73 26.73 24.31 25.83 24.56C25.36 24.69 24.5 24.78 23.18 24.84C21.88 24.91 20.69 24.94 19.59 24.94L18 25C13.81 25 11.2 24.84 10.17 24.56C9.27 24.31 8.69 23.73 8.44 22.83C8.31 22.36 8.22 21.73 8.16 20.93C8.09 20.13 8.06 19.44 8.06 18.84L8 18C8 15.81 8.16 14.2 8.44 13.17C8.69 12.27 9.27 11.69 10.17 11.44C10.64 11.31 11.5 11.22 12.82 11.16C14.12 11.09 15.31 11.06 16.41 11.06L18 11C22.19 11 24.8 11.16 25.83 11.44C26.73 11.69 27.31 12.27 27.56 13.17Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_2047_39420">
                        <rect
                            className="social-secondary-color"
                            width="36"
                            height="36"
                            fill="white"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default YoutubeIcon;
