import { useState } from "react";
import './AdminDashboard.scss';
import { DashboardItem } from "../../models/DashboardItem";

export const AdminDashboard = (props: {}) => {
    const [dashboardItems] = useState([
        new DashboardItem("1", "Goals", "pi pi-home", "/admin/goals", "Goals"),
        new DashboardItem("2", "Salespeople", "pi pi-users", "/admin/salespeople", "Salespeople"),
    ]);
    return (
        <>
            <div className="dashboard-container">
                {dashboardItems.map((item) => {
                    return (
                        <div onClick={()=> {window.location.href = item.route}} key={item.id} className="dashboard-item">
                            <div className="dashboard-item-icon">
                                <i className={item.icon}></i>
                            </div>
                            <div className="dashboard-item-content">
                                <div className="dashboard-item-name">
                                    {item.name}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    )
};