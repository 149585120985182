import React from "react"
import Button from "../button/Button"
import { RichText } from "../richtext/RichText"
import { GetRawKey, ScrollToElement } from "../../services/DataService"
import { GetColor } from "../../services/ColorService"
import "./CallToAction.scss"

export default function SectionCallToAction(props: any) {
    const GetDescription = (data: any) => {
        if (data["en"]) {
            return data["en"]
        }
        if (data[GetRawKey("en")]) {
            return data[GetRawKey("en")]
        }
        return null
    }
    const title = props.translatedTitle ? props.translatedTitle : props.title && props.title["en"] ? props.title["en"] : null
    const description = props.translatedDescription ? props.translatedDescription : props.description ? GetDescription(props.description) : null
    const buttonText = props.buttonText && props.buttonText["en"] ? props.buttonText["en"] : "Learn More"

    let className = props.className ? props.className : ""

    if (title || description || (buttonText && props.onButtonClick)) {
        return (
            <section className={className}>
                {(title || description) && (
                    <div className={className + "__wrapper"}>
                        {title && (
                            <h3
                                className={className + "__title"}
                                data-testid={"section-cta-title"}
                                // style={{
                                //   "--title-decoration": `${GetColor(props.color) as any}`,
                                // }}
                            >
                                {title}
                            </h3>
                        )}
                        {description && typeof description === "string" && (
                            <p data-testid={"section-cta-description-string"} className={className + "__description"}>
                                {description}
                            </p>
                        )}
                        {description && typeof description !== "string" && (
                            <div data-testid={"section-cta-description-rich-text"} className={className + "__description"}>
                                <RichText item={description} color="" className="" />
                            </div>
                        )}
                    </div>
                )}
                {props.onButtonClick && buttonText && (
                    <Button
                        className={"btn__primary btn__primary--large"}
                        text={buttonText}
                        onClick={() => {
                            props.onButtonClick()
                        }}
                        color={props.color}
                        hasArrow={true}
                        branding={"PCG"}
                    />
                )}
                {props.href && buttonText && <Button className={"btn__primary btn__primary--large"} text={buttonText} href={props.href} linkType={props.linkType} newTab={props.newTab} color={props.color} hasArrow={true} branding={"PCG"} />}
                {!props.href && props.elementId && buttonText && <Button className={"btn__primary btn__primary--large"} text={buttonText} onClick={() => ScrollToElement(props.elementId, "nearest", "start", 140)} color={props.color} hasArrow={true} branding={"PCG"} />}
            </section>
        )
    } else {
        return null
    }
}
