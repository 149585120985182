export const useInternalPortalAuthProvider = () => {
  const generateSsoUrl = () => {
    return `${process.env.REACT_APP_AWS_SSO_PROVIDER_URL}?identity_provider=pcg-import-hub&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&scope=email openid phone profile aws.cognito.signin.user.admin`;
  };

  const ssoLoginUrl = generateSsoUrl();
  return {
    ssoLoginUrl,
  };
};
