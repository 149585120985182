import React from "react"
import HeadingTag from "../../../common/components/HeadingTag/HeadingTag"
import { DocumentAuthor } from "./author/DocumentAuthor"
import "./SectionDocumentAuthors.scss"

interface SectionDocumentAuthorsProps {
    authors: any[]
}

export const SectionDocumentAuthors: React.FC<SectionDocumentAuthorsProps> = ({ authors }) => {
    return (
        <section className="section-authors">
            <HeadingTag type="h3" className="section-authors__heading">
                Authors
            </HeadingTag>
            <div className="section-authors__people grid grid-two">
                {authors.map((author, index) => (
                    <DocumentAuthor key={index} author={author} />
                ))}
            </div>
        </section>
    )
}
