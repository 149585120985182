import moment from "moment"
import { SanityUser } from "../../types"
import "./version-history-card.scss"

export const VersionHistoryCard = (props: {
    version: {
        version: number
        date: string
        description: string
        updatedBy: SanityUser
    }
    key: number
}) => {
    const { version, key } = props
    return (
        <div className="version" key={key}>
            <div className="version-number-line-wrapper">
                <div className="version-number">{version.version.toString().padStart(3, "0")}</div>
                <div className="line">
                    <div className="circle"></div>
                </div>
            </div>
            <div className="version-data">
                <div className="version-data-wrapper">
                    <h6>{moment(version.date).format("YYYY-MM-DD")}</h6>
                    <div className="version-data-wrapper-description">
                        <div className="version-data-wrapper-description-text">{version.description}</div>
                        <div className="version-data-wrapper-description-author">
                            Updated by: <span>{version.updatedBy.name}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
