import React from "react"
import FacebookIcon from "../components/elements/social-icons/facebook"
import LinkedinIcon from "../components/elements/social-icons/linkedin"
import InstagramIcon from "../components/elements/social-icons/instagram"
import YoutubeIcon from "../components/elements/social-icons/youtube"
import XIcon from "../components/elements/social-icons/x"

export type SocialMediaType = "facebook" | "linkedin" | "instagram" | "youtube" | "x"

export function renderSocialMediaIcon(type: SocialMediaType): JSX.Element | null {
    switch (type) {
        case "facebook":
            return <FacebookIcon />
        case "linkedin":
            return <LinkedinIcon />
        case "instagram":
            return <InstagramIcon />
        case "youtube":
            return <YoutubeIcon />
        case "x":
            return <XIcon />
        default:
            return null
    }
}
