import React, { FC, ReactNode, useState, useEffect } from "react"
import "./column.scss"

interface CustomColumnProps {
    field: string
    header?: boolean
    headerText?: string
    sortable?: boolean
    data?: any
    body?: (data: any) => ReactNode
    onClick?: () => void
    sortOrder?: number
    sortIcon?: (options: any) => JSX.Element
}

function CalculateColumnWidth(screenWidth: number, column: string | undefined) {
    let columnWidth
    if (screenWidth > 1440) {
        switch (column) {
            case "Customer":
                columnWidth = {
                    minWidth: "220px",
                }
                break
            case "Case study":
                columnWidth = {
                    minWidth: "220px",
                }
                break
            case "Country":
                columnWidth = {
                    width: "140px",
                }
                break
            case "Industry":
            case "Hyperscaler":
            case "Domain":
            case "Owner":
                columnWidth = {
                    width: "180px",
                }
                break
            default:
                columnWidth = {
                    width: "160px",
                }
                break
        }
    } else if (screenWidth >= 1248) {
        switch (column) {
            case "Customer":
            case "Case study":
                columnWidth = {
                    width: "220px",
                }
                break
            case "Country":
                columnWidth = {
                    width: "140px",
                }
                break
            case "Industry":
            case "Hyperscaler":
                columnWidth = {
                    width: "180px",
                }
                break
            case "Domain":
            case "Owner":
                columnWidth = {
                    width: "180px",
                }
                break
            default:
                columnWidth = {
                    width: "160px",
                }
                break
        }
    } else {
        switch (column) {
            case "Customer":
            case "Case study":
            case "Owner":
                columnWidth = {
                    width: "180px",
                }
                break
            case "Domain":
            case "Country":
                columnWidth = {
                    width: "140px",
                }
                break
            case "Industry":
            case "Hyperscaler":
                columnWidth = {
                    width: "160px",
                }
                break
            default:
                columnWidth = {
                    width: "160px",
                }
                break
        }
    }
    return columnWidth
}

const CustomColumn: FC<CustomColumnProps> = ({ field, header = false, headerText, sortable, data, body, onClick, sortOrder, sortIcon }) => {
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    if (header) {
        let columnWidth = CalculateColumnWidth(windowDimensions.width, headerText)

        return (
            <th onClick={sortable ? onClick : undefined} style={columnWidth}>
                {headerText}
                {sortable && sortIcon ? sortIcon({ sorted: !!sortOrder, sortOrder }) : null}
            </th>
        )
    }

    return (
        <td>
            <div className="scrollable">{body ? body(data) : data[field]}</div>
        </td>
    )
}

export default CustomColumn
