import { HubspotOwner } from "./models/HubspotOwner";
import { ParquetCompanyPayload } from "./models/ParquetCompanyPayload";

export const mapResultToObject = (objectType: string, object: any) => {
    switch (objectType) {
        case 'owners':
            return mapToOwner(object);
        case 'companies':
            return mapToCompany(object);
        default:
            return object;
    }
}

const mapToOwner = (object: any) => {
    return new HubspotOwner(object[0], object[1], object[2], object[3], object[4]);
}

const mapToCompany = (object: any) => {
    return new ParquetCompanyPayload(object[2], object[6], object[3], object[0], object[1], object[4], object[5]);
}