import React from "react";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";
import zoomInIcon from "../../assets/img/icons/diagram_zoom_in.svg";
import zoomOutIcon from "../../assets/img/icons/diagram_zoom_out.svg";
import resetIcon from "../../assets/img/icons/diagram_reset.svg";
import centerIcon from "../../assets/img/icons/diagram_center.svg";
import "./PanAndZoomDiagram.scss";

const PanningInteractionHandler = (instance: any, state: any) => {
  if (state?.positionX >= 0 && state?.positionY >= 0 && state?.scale <= 1) {
    instance.setup.panning.disabled = true;
  } else {
    instance.setup.panning.disabled = false;
  }
};

export default function PanAndZoomDiagram(props: any) {
  if (props.image) {
    return (
      <div data-testid={"pan-and-zoom-diagram"} className={"pan-and-zoom"}>
        <TransformWrapper
          centerOnInit={true}
          centerZoomedOut={true}
          onZoomStop={(ref) => {
            PanningInteractionHandler(ref.instance, ref.state);
          }}
          onPanning={(ref) => {
            PanningInteractionHandler(ref.instance, ref.state);
          }}
          onInit={(ref) => {
            ref.instance.setup.panning.disabled = true;
          }}
        >
          {({
            zoomIn,
            zoomOut,
            resetTransform,
            centerView,
            instance,
          }: ReactZoomPanPinchContentRef) => (
            <div className={"diagram-container"}>
              <TransformComponent contentStyle={{ width: "100%" }}>
                <img
                  alt={props.altText ? props.altText : "Architectural diagram"}
                  src={props.image.asset.url}
                  className="diagram"
                ></img>
              </TransformComponent>
              <div className={"diagram-tools"}>
                <button
                  onClick={() => {
                    zoomIn();
                    instance.setup.panning.disabled = false;
                  }}
                  data-testid={"button-zoom-in"}
                >
                  <img
                    src={zoomInIcon}
                    alt="Zoom In Icon"
                    width={25}
                    height={25}
                  />
                </button>
                <button
                  onClick={() => {
                    zoomOut();
                    PanningInteractionHandler(instance, null);
                  }}
                  data-testid={"button-zoom-out"}
                >
                  <img
                    src={zoomOutIcon}
                    alt="Zoom Out Icon"
                    width={25}
                    height={25}
                  />
                </button>
                <button
                  onClick={() => {
                    resetTransform();
                    instance.setup.panning.disabled = true;
                  }}
                  data-testid={"button-reset"}
                >
                  <img
                    src={resetIcon}
                    alt="Reset Icon"
                    width={25}
                    height={25}
                  />
                </button>
                <button
                  onClick={() => centerView()}
                  data-testid={"button-center"}
                >
                  <img
                    src={centerIcon}
                    alt="Center Icon"
                    width={25}
                    height={25}
                  />
                </button>
              </div>
            </div>
          )}
        </TransformWrapper>

        {props.title && props.title["en"] && (
          <div className={"image-description"}>
            {props.title && (
              <p
                className={"image-description__title"}
                data-testid={"diagram-title"}
              >
                {props.title["en"]}
              </p>
            )}
            {props.description && props.description["en"] && (
              <p
                className={"image-description__description"}
                data-testid={"diagram-description"}
              >
                {props.description["en"]}
              </p>
            )}
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
}
