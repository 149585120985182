import { useState } from "react"

const useSectionPosition = (props) => {
    const [activeItem, setActiveItem] = useState(null)
    const [sectionPositions, setSectionPositions] = useState([])

    const calculateSectionPositions = () => {
        if (Array.isArray(props.items)) {
            const newSectionPositions = props.items.map((item, index) => {
                const headings = document.querySelectorAll(item.style)
                const filteredHeadings = Array.from(headings).filter((el) => el.className.trim() !== "dot-decoration")
                const section = filteredHeadings[item.index]
                if (section) {
                    const rect = section.getBoundingClientRect().top
                    const offsetPosition = rect + window.scrollY - 150

                    return {
                        id: index,
                        top: Math.round(offsetPosition),
                    }
                }
                return null
            })

            setSectionPositions(newSectionPositions)
        }
    }

    const handleScroll = () => {
        calculateSectionPositions()

        const isScrolledToBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight
        let closestSection = null
        for (const section of sectionPositions) {
            if (section && window.scrollY >= section.top && !isScrolledToBottom) {
                closestSection = section.id
            } else if (isScrolledToBottom) {
                closestSection = sectionPositions.length - 1
            }
        }

        setActiveItem(closestSection)
    }

    const isBrowser = () => typeof window !== "undefined"

    isBrowser() && window.addEventListener("scroll", handleScroll)

    return activeItem
}

export default useSectionPosition
