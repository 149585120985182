import { S3Driver } from './S3Driver';
export class S3OwnersContext {
    constructor(
        public bucket: string = "pcg-datalake",
        public driver: S3Driver = new S3Driver("pcg-datalake", "eu-north-1"
        , process.env.REACT_APP_AWS_ACCESS_KEY || ""
        , process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || "")
    ) {
    }

    getOwners = async (onComplete: Function) => {
        return await this.driver.download("hubspot/owners/owners_data.snappy.parquet", onComplete, 'owners');
    }

    getCountries = async () => {
        return await this.driver.getCountries();
    }

    getGoals = async () => {
        return await this.driver.getGoals();
    }

    saveGoals = async (goals: any) => {
        return await this.driver.saveGoals(goals);
    }

    saveCountries = async (countries: any) => {
        return await this.driver.saveCountries(countries);
    }

    getCompanies = async (onComplete: Function) => {
        let time = new Date();
        let year = time.getFullYear();
        let month = (time.getMonth() + 1) < 10 ? `0${time.getMonth() + 1}` : time.getMonth() + 1;
        let day = time.getDate() < 10 ? `0${time.getDate()}` : time.getDate();
        
        try {
            return await this.driver.download(`hubspot/companies/year=${year}/month=${month}/day=${day}/companies_data_${year}_${month}_${day}.snappy.parquet`, onComplete, 'companies');
        } catch (error: any) {
            if(error.name === 'NoSuchKey')
                {
                    day = time.getDate()-1 < 10 ? `0${time.getDate()-1}` : time.getDate()-1;

                    return await this.driver.download(`hubspot/companies/year=${year}/month=${month}/day=${day}/companies_data_${year}_${month}_${day}.snappy.parquet`, onComplete, 'companies');
                }

        }
        return "";
    }


}