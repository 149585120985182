import { ValidationResult } from "../../sales-import/models/ValidationResult"
import { storageService } from "./StorageService"

export const validateColumns = (columnList: string[], rowCount: number = 0) => {
    const missingColumns: string[] = []

    storageService
        .getFields()
        .map((c) => c)
        .forEach((column) => {
            if (!columnList.includes(column)) {
                missingColumns.push(column)
            }
        })

    return new ValidationResult(missingColumns.length === 0, missingColumns.length, rowCount, missingColumns)
}

export const typeShortToFull = (type: keyof typeof typeMap) => {
    const typeMap = {
        P: "policy",
        Pr: "process",
        G: "guide",
        C: "checklist",
        T: "template",
        DB: "database",
        KPIs: "kpis",
        Wb: "workbook",
        Pt: "presentation",
        KB: "knowledgebase",
    }

    return typeMap[type]
}

export const typeFullToShort = (type: keyof typeof typeMapReversed) => {
    const typeMapReversed = {
        policy: "P",
        process: "Pr",
        guide: "G",
        checklist: "C",
        template: "T",
        database: "DB",
        kpis: "KPIs",
        workbook: "Wb",
        presentation: "Pt",
        knowledgebase: "KB",
    }

    return typeMapReversed[type]
}
