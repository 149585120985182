import { typeFullToShort } from "../../common/services/util-functions"

export const filterByType = (documents: any[], selectedTypes: any[]) => {
    if (selectedTypes.length > 0) {
        return documents.filter((document: any) => selectedTypes.some((selectedType) => typeFullToShort(selectedType) === document.documentType))
    }
    return documents
}

export const filterByDepartment = (documents: any[], selectedDepartments: any[], allDepartments: any[]) => {
    const departmentAbbreviations = new Set(allDepartments.map((department) => department.abbreviation))

    if (selectedDepartments.length > 0) {
        return documents.filter((document: any) => {
            const { global, viewingRights, owner, teamsWithViewingRights } = document

            return selectedDepartments.some((selectedDepartment) => {
                if (!departmentAbbreviations.has(selectedDepartment)) {
                    return false
                }

                if (global) {
                    return true
                } else if (viewingRights === "owner" || viewingRights === "public") {
                    return selectedDepartment === owner?.abbreviation
                } else if (viewingRights === "teams") {
                    return teamsWithViewingRights && teamsWithViewingRights.some((team: any) => team.abbreviation === selectedDepartment)
                }

                return false
            })
        })
    }

    return documents
}

export const filterByApplicable = (documents: any[], selectedApplicables: any[]) => {
    if (selectedApplicables.length > 0) {
        return documents.filter((document: any) => {
            return selectedApplicables.some((selectedApplicable) => {
                return document.applicableFor && document.applicableFor[selectedApplicable] === true
            })
        })
    }
    return documents
}

export const applyFiltersAndPaginate = (documents: any[], selectedTypes: any[], selectedDepartments: any[], allDepartments: any[], selectedApplicables: any[], page: number, pageSize: number = 8) => {
    let filteredDocuments = filterByType(documents, selectedTypes)
    filteredDocuments = filterByDepartment(filteredDocuments, selectedDepartments, allDepartments)
    filteredDocuments = filterByApplicable(filteredDocuments, selectedApplicables)

    const totalCount = filteredDocuments.length

    const startIndex = (page - 1) * pageSize
    const endIndex = startIndex + pageSize

    return { totalCount, updatedDocuments: filteredDocuments.slice(startIndex, endIndex) }
}
