import { CognitoIdentityProviderClient, GetUserCommand, GetUserResponse } from "@aws-sdk/client-cognito-identity-provider"

import axios from "axios"

interface Tokens {
    access_token: string
    id_token: string
    refresh_token: string
    expires_in: number
    token_type: string
}

console.log("STAGE: ", process.env.REACT_APP_STAGE)
const cognito = new CognitoIdentityProviderClient({
    region: process.env.REACT_APP_STAGE === "local" ? "eu-north-1" : "eu-west-1",
})

const getUserAttributes = async (accessToken: string) => {
    const input = {
        // GetUserRequest
        AccessToken: accessToken, // required
    }
    const command = new GetUserCommand(input)
    const response: GetUserResponse = await cognito.send(command)
    let obj: any = {}
    response.UserAttributes?.map((item) => {
        obj[item.Name as string] = item.Value
    })
    return obj
}

const exchangeCodeForTokens = async (code: string): Promise<Tokens> => {
    try {
        const response = await axios.post<Tokens>(
            `${process.env.REACT_APP_AWS_SSO_TOKEN_URL}`,
            {
                grant_type: "authorization_code",
                code: code,
                redirect_uri: process.env.REACT_APP_REDIRECT_URI,
                client_id: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        )
        const data: Tokens = response.data

        return data
    } catch (error) {
        console.log("Error: ", error)
        throw error
    }
}

const refreshTokens = async (refreshToken: string): Promise<Tokens> => {
    try {
        const response = await axios.post<Tokens>(
            `${process.env.REACT_APP_AWS_SSO_TOKEN_URL}`,
            {
                grant_type: "refresh_token",
                refresh_token: refreshToken,
                client_id: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        )
        const data: Tokens = response.data
        return data
    } catch (error) {
        throw error
    }
}

export { getUserAttributes, exchangeCodeForTokens, refreshTokens }
