import React, { useEffect, useRef, useState } from "react"
import "./CaseStudies.scss"
import "../../../common/assets/flags.scss"
import "primereact/resources/themes/lara-dark-cyan/theme.css"
import { INDUSTRY_OPTIONS } from "../../constants/industry"
import { determineLocale, parseContent, normalizeString, normalizeValues } from "../../util/customer_table_render_helpers"
import { ENDPOINTS } from "../../constants/endpoints"
import { SkeletonWrapper } from "../../../common/components/SkeletonWrapper/SkeletonWrapper"
import { useNotifications } from "../../../common/hooks/notifications.hook"
import { filterBySearchFields, filterByCountry, filterByIndustry, filterByOwner, filterByLanguage } from "../../util/filter_table_helpers"
import { Tooltip } from "primereact/tooltip"
import { useLocation } from "react-router"
import { useSanityApi } from "../../hooks/sanityApi.hook"
import HubspotLogo from "../../../common/assets/img/logo-hubspot/logo-hubspot.svg"
import QuestionMark from "../../../common/assets/img/icons/question.svg"
import CheckMark from "../../../common/assets/img/icons/check.svg"
import SortIcon from "../../../common/assets/img/icons/sort.svg"
import Filter from "../../../common/assets/img/icons/filter.svg"
import SortAscIcon from "../../../common/assets/img/icons/sort-ascending.svg"
import SortDescIcon from "../../../common/assets/img/icons/sort-descending.svg"
import Pagination from "../../../common/components/pagination/pagination"
import SearchField, { SearchFieldRef } from "../../../common/components/SearchField/SearchField"
import HeadingTag from "../../../common/components/HeadingTag/HeadingTag"
import MultiOptionFilter from "../../../common/components/filters/multi-options-filter"
import Button from "../../../common/components/button/Button"
import CustomDataTable from "../../../common/components/table/Table"
import CustomColumn from "../../../common/components/table/Column"
import ImageText from "../../../common/components/image-text/ImageText"
import { useTranslation } from "react-i18next"

interface FilterComponent {
    className: string
    color: string
    onChange?: (values: string[]) => void
    filter_category_name: string
    activeValues: string[]
    initSize: number
}

export const CaseStudies = () => {
    const sanityApi = useSanityApi()
    const { t } = useTranslation()

    const [searchField, setSearchField] = useState("")
    const [selectedCountries, setSelectedCountries] = useState<any>([])
    const [selectedIndustries, setSelectedIndustries] = useState<any>([])
    const [selectedOwners, setSelectedOwners] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [selectedLanguages, setSelectedLanguages] = useState<string[]>([])

    const notifications = useNotifications()
    const location = useLocation()
    const ref = useRef<SearchFieldRef>(null)
    const dropdownRef = useRef<any>(null)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [showFilters, setShowFilters] = useState(false)

    let industriesFilterComponent: FilterComponent = {
        filter_category_name: "industries",
        className: "insights--industries",
        color: "clr-tertiary-500",
        activeValues: selectedIndustries,
        initSize: 15,
    }
    let languagesFilterComponent: FilterComponent = {
        filter_category_name: "languages",
        className: "insights--languages",
        color: "clr-tertiary-500",
        activeValues: selectedLanguages,
        initSize: 15,
    }
    let countriesFilterComponent: FilterComponent = {
        filter_category_name: "countries",
        className: "insights--countries",
        color: "clr-tertiary-500",
        activeValues: selectedCountries,
        initSize: 15,
    }
    let ownersFilterComponent: FilterComponent = {
        filter_category_name: "owners",
        className: "insights--owners",
        color: "clr-tertiary-500",
        activeValues: selectedOwners,
        initSize: 15,
    }

    let allSelectedFilters = selectedIndustries + "," + selectedLanguages + "," + selectedCountries + "," + selectedOwners

    let allSelectedFiltersArray = allSelectedFilters.split(",").filter(Boolean)

    const toggleFilters = () => {
        setShowFilters(!showFilters)
    }

    const industriesOptions =
        sanityApi.industries &&
        Object.values(sanityApi.industries)
            .map((industry: any) => {
                return {
                    key: industry,
                    value: industry,
                }
            })
            .sort((a, b) => a.key.localeCompare(b.key))

    const languagesOptions =
        sanityApi.languages &&
        Object.values(sanityApi.languages)
            .map((language: any) => {
                return {
                    key: language,
                    value: language,
                }
            })
            .sort((a, b) => a.key.localeCompare(b.key))

    const countriesOptions =
        sanityApi.countries &&
        Object.values(sanityApi.countries)
            .map((country: any) => {
                return {
                    key: country,
                    value: country,
                }
            })
            .sort((a, b) => a.key.localeCompare(b.key))

    const ownersOptions =
        sanityApi.owners &&
        Object.values(sanityApi.owners)
            .map((owner: any) => {
                return {
                    key: owner,
                    value: owner,
                }
            })
            .sort((a, b) => a.key.localeCompare(b.key))

    const handleFilterChange = (filterType: string, selectedValues: string[]) => {
        switch (filterType) {
            case "industries":
                setSelectedIndustries(selectedValues)
                break
            case "languages":
                setSelectedLanguages(selectedValues)
                break
            case "countries":
                setSelectedCountries(selectedValues)
                break
            case "owners":
                setSelectedOwners(selectedValues)
                break
            default:
                break
        }
        filterCompanies()
    }

    const processAndRenderLanguages = (caseStudies: any[], e: any) => {
        let caseStudy = caseStudies.find((sanityCompany: any) => sanityCompany.name?.includes(e.name) || e.name?.includes(sanityCompany.name) || (sanityCompany.companyId != null && (e.company_id?.includes(sanityCompany.companyId.toString()) || sanityCompany.companyId?.toString().includes(e?.company_id))))
        if (caseStudy)
            return (
                <div className="flags-row">
                    {caseStudy.languages.map((language: any, index: number) => {
                        return (
                            <a target="_blank" key={index} rel="noreferrer" href={`${ENDPOINTS.SANITY_INSIGHTS_URL}${determineLocale(language)}/${caseStudy.slugs[`slug_${language}`].current}`} className="flag-a">
                                <div>
                                    <img alt="flag" src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`flag flag-${language}`} />
                                </div>
                            </a>
                        )
                    })}
                </div>
            )
    }

    const handleClickOutside = (event: any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowFilters(false)
        }
    }

    useEffect(() => {
        sanityApi.getAndLoadProfileForCustomers()
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (location.search && !sanityApi.loading) {
            setFiltersFromUrl(location.search)
        }
    }, [sanityApi.loading])

    const OnPageChange = (newPage: number) => {
        setCurrentPage(newPage)
    }

    useEffect(() => {
        filterCompanies()
        const filters = {
            page: currentPage,
            industries: selectedIndustries,
            countries: selectedCountries,
            owners: selectedOwners,
            languages: selectedLanguages,
        }
        const search = buildSearchString(filters, searchField)
        window.history.pushState(null, "RedirectUrl", search)
    }, [searchField, selectedCountries, selectedIndustries, selectedOwners, selectedLanguages, sanityApi.companies, currentPage])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    const setFiltersFromUrl = (search: string) => {
        const params = new URLSearchParams(search)
        const searchedIndustries = params.get("industries")
        const searchCountries = params.get("countries")
        const searchedOwners = params.get("owners")
        const searchedLanguages = params.get("languages")
        const search_field = params.get("search")
        const page = params.get("page")
        setSelectedIndustries(searchedIndustries ? Array.from(new Set(searchedIndustries.split("_").map((key) => sanityApi.industries[key] || ""))) : [])
        setSelectedCountries(searchCountries ? Array.from(new Set(searchCountries.split("_").map((key) => sanityApi.countries[key] || ""))) : [])
        setSelectedOwners(searchedOwners ? Array.from(new Set(searchedOwners.split("_").map((key) => sanityApi.owners[key] || ""))) : [])
        setSelectedLanguages(searchedLanguages ? Array.from(new Set(searchedLanguages.split("_").map((key) => sanityApi.languages[key] || ""))) : [])
        setSearch(search_field ? search_field : "")
        setCurrentPage(page ? parseInt(page) : 1)
    }

    const customSortIcon = (options: any) => {
        let icon = options.sorted ? options.sortOrder < 0 ? <img className="sort-icon" src={SortDescIcon} /> : <img className="sort-icon" src={SortAscIcon} /> : <img className="sort-icon" src={SortIcon} />
        return icon
    }

    const resetAllFilters = () => {
        setSelectedIndustries([])
        setSelectedCountries([])
        setSelectedOwners([])
        setSelectedLanguages([])
        setSearch("")
    }

    const resetIndustries = () => {
        setSelectedIndustries([])
    }
    const resetCountries = () => {
        setSelectedCountries([])
    }
    const resetOwners = () => {
        setSelectedOwners([])
    }
    const resetLanguages = () => {
        setSelectedLanguages([])
    }
    const buildSearchString = (filters: any, search_field: string) => {
        let search = "/case-studies"
        const filterKeys = Object.keys(filters)

        filterKeys.forEach((key) => {
            const values = filters[key]
            if (key === "page" && values === 1) {
                return
            }
            if (values.length > 0 || (key === "page" && values !== 1)) {
                search += search.indexOf("?") === -1 ? "?" : "&"
                let normalizedValues
                if (Array.isArray(values)) {
                    normalizedValues = normalizeValues(values)
                } else {
                    normalizedValues = typeof values === "string" ? normalizeString(values) : values
                }
                search += key + "=" + normalizedValues
            }
        })

        if (search_field.length >= 3) {
            search += search.indexOf("?") === -1 ? "?" : "&"
            search += "search" + "=" + encodeURIComponent(search_field)
        }

        return search
    }

    const filterCompanies = () => {
        let filteredCompanies = sanityApi.companies

        if (searchField) {
            filteredCompanies = filterBySearchFields(filteredCompanies, searchField)
        }
        if (selectedCountries.length > 0) {
            filteredCompanies = filterByCountry(filteredCompanies, selectedCountries)
        }
        if (selectedIndustries.length > 0) {
            filteredCompanies = filterByIndustry(filteredCompanies, selectedIndustries)
        }
        if (selectedOwners.length > 0) {
            filteredCompanies = filterByOwner(filteredCompanies, selectedOwners)
        }
        if (selectedLanguages.length > 0) {
            filteredCompanies = filterByLanguage(filteredCompanies, selectedLanguages)
        }
        sanityApi.setHubspotCompanies(filteredCompanies)
    }

    const generateSanityLink = (caseStudies: any[], e: any) => {
        let caseStudy = caseStudies.find((sanityCompany: any) => sanityCompany.name?.includes(e.name) || e.name?.includes(sanityCompany.name) || (sanityCompany.companyId != null && (e.company_id?.includes(sanityCompany.companyId?.toString()) || sanityCompany.companyId?.toString().includes(e?.company_id))))
        if (caseStudy) {
            return caseStudy.isDraft ? `{DRAFT} ${caseStudy.studyTitle}` : caseStudy.studyTitle
        } else return "No case study yet"
    }

    const companies = sanityApi.companies

    const pageSize = 10
    let firstPageIndex = currentPage * pageSize - pageSize
    let lastPageIndex = 0
    for (let i = firstPageIndex; i < currentPage * pageSize; i++) {
        if (companies[i]) {
            lastPageIndex = i
        }
    }
    lastPageIndex = lastPageIndex + 1

    const setSearch = (search_term: string) => {
        const value = search_term.replace(/[/\\#^+()$£~%.,_\[=`":;*?<>|{}!@§±\]]/gi, "").replace(/\s+/, " ")
        setSearchField(value)
        ref.current?.setSearchField(value)
    }

    const renderFilters = () => (
        <SkeletonWrapper isLoading={sanityApi.loading} width="100%" height="5vh">
            <MultiOptionFilter className={countriesFilterComponent.className} color={countriesFilterComponent.color} options={countriesOptions} onChange={(selectedValues) => handleFilterChange("countries", selectedValues)} filter_category_name={countriesFilterComponent.filter_category_name} activeValues={selectedCountries} filterType={"countries"} resetAllFilters={resetCountries} />
            <MultiOptionFilter className={ownersFilterComponent.className} color={ownersFilterComponent.color} options={ownersOptions} onChange={(selectedValues) => handleFilterChange("owners", selectedValues)} filter_category_name={ownersFilterComponent.filter_category_name} activeValues={selectedOwners} filterType={"owners"} resetAllFilters={resetOwners} />
            <MultiOptionFilter className={industriesFilterComponent.className} color={industriesFilterComponent.color} options={industriesOptions} onChange={(selectedValues) => handleFilterChange("industries", selectedValues)} filter_category_name={industriesFilterComponent.filter_category_name} activeValues={selectedIndustries} filterType={"industries"} resetAllFilters={resetIndustries} />
            <MultiOptionFilter className={languagesFilterComponent.className} color={languagesFilterComponent.color} options={languagesOptions} onChange={(selectedValues) => handleFilterChange("languages", selectedValues)} filter_category_name={languagesFilterComponent.filter_category_name} activeValues={selectedLanguages} filterType={"languages"} resetAllFilters={resetLanguages} />
            {(selectedIndustries.length > 0 || selectedCountries.length > 0 || selectedOwners.length > 0 || selectedLanguages.length > 0 || searchField.length >= 3) && (
                <Button
                    className="btn__icon--circle__insights--clear_filters btn-close"
                    color={"white"}
                    onClick={() => {
                        resetAllFilters()
                    }}
                />
            )}
        </SkeletonWrapper>
    )

    const renderFiltersDropdown = () => (
        <div ref={dropdownRef} className="filters-dropdown">
            <SkeletonWrapper isLoading={sanityApi.loading} width="100%" height="5vh">
                <div className="filters-dropdown__inner">
                    <MultiOptionFilter className={countriesFilterComponent.className} color={countriesFilterComponent.color} options={countriesOptions} onChange={(selectedValues) => handleFilterChange("countries", selectedValues)} filter_category_name={countriesFilterComponent.filter_category_name} activeValues={selectedCountries} filterType={"countries"} resetAllFilters={resetCountries} />
                    <MultiOptionFilter className={ownersFilterComponent.className} color={ownersFilterComponent.color} options={ownersOptions} onChange={(selectedValues) => handleFilterChange("owners", selectedValues)} filter_category_name={ownersFilterComponent.filter_category_name} activeValues={selectedOwners} filterType={"owners"} resetAllFilters={resetOwners} />
                    <MultiOptionFilter className={industriesFilterComponent.className} color={industriesFilterComponent.color} options={industriesOptions} onChange={(selectedValues) => handleFilterChange("industries", selectedValues)} filter_category_name={industriesFilterComponent.filter_category_name} activeValues={selectedIndustries} filterType={"industries"} resetAllFilters={resetIndustries} />
                    <MultiOptionFilter className={languagesFilterComponent.className} color={languagesFilterComponent.color} options={languagesOptions} onChange={(selectedValues) => handleFilterChange("languages", selectedValues)} filter_category_name={languagesFilterComponent.filter_category_name} activeValues={selectedLanguages} filterType={"languages"} resetAllFilters={resetLanguages} />
                    {(selectedIndustries.length > 0 || selectedCountries.length > 0 || selectedOwners.length > 0 || selectedLanguages.length > 0 || searchField.length >= 3) && (
                        <div className="multi-filters">
                            <Button
                                className="btn__filter__insights"
                                text={"Reset all"}
                                onClick={() => {
                                    resetAllFilters()
                                }}
                            ></Button>
                        </div>
                    )}
                </div>
            </SkeletonWrapper>
        </div>
    )

    return (
        <div className="companies-container">
            <section className="section-wrapper">
                <SkeletonWrapper isLoading={sanityApi.loading} width="100%" height="5vh">
                    <div className="title-wrapper">
                        <div>
                            <HeadingTag className="heading__tag" type="h2">
                                Case Studies
                            </HeadingTag>
                        </div>
                        <div className="column search__container">
                            <p className="search__field">
                                <SearchField onClick={setSearch} ref={ref}></SearchField>
                            </p>
                        </div>
                    </div>
                </SkeletonWrapper>
            </section>
            <section className="columns-search-box section-wrapper">
                <SkeletonWrapper isLoading={sanityApi.loading}>
                    {windowWidth < 1024 ? (
                        <div>
                            <div>
                                <button className={`btn-filters ${showFilters ? "btn-filters-active" : ""}`} onClick={toggleFilters}>
                                    {" "}
                                    <img src={Filter} className="filter-icon" alt="Scroll to top" />
                                    <span className="filters-text">Filters</span>
                                    {allSelectedFiltersArray.length > 0 && (
                                        <span className="number-span">
                                            (<span className="number">{allSelectedFiltersArray.length}</span>)
                                        </span>
                                    )}
                                </button>{" "}
                            </div>{" "}
                            {showFilters && renderFiltersDropdown()}
                        </div>
                    ) : (
                        renderFilters()
                    )}

                    {windowWidth >= 1440 && (
                        <div className="pagination-top">
                            <Pagination className="pagination" items={sanityApi.hubspotCompanies} pageSize={pageSize} OnPageChange={OnPageChange} currentPage={currentPage} />
                        </div>
                    )}
                </SkeletonWrapper>
            </section>
            <SkeletonWrapper isLoading={sanityApi.loading} width="100%" height="80vh">
                {sanityApi?.hubspotCompanies?.length > 0 ? (
                    <CustomDataTable scrollable scrollHeight="65vh" showGridlines stripedRows rows={pageSize} value={sanityApi.hubspotCompanies.slice(firstPageIndex, lastPageIndex)} sortIcon={customSortIcon}>
                        <CustomColumn
                            sortable
                            field="customer"
                            headerText="Customer"
                            body={(e) => (
                                <div className="company-table-container__customer_case-study">
                                    <div className="column_name">
                                        <div className="hubspot-logo">
                                            <a target="_blank" rel="noreferrer" href={`${ENDPOINTS.HUBSPOT_COMPANY_URL}/${e.company_id}`}>
                                                <img className="hubspot-pcg" src={HubspotLogo} />
                                            </a>
                                        </div>
                                        <div className="company-table-container__name">{e.name}</div>
                                        <div className="is-match">
                                            <Tooltip target=".is-match__icon" mouseTrack mouseTrackLeft={0} mouseTrackTop={0} />
                                            <img className="is-match__icon" src={e.isMatch ? CheckMark : QuestionMark} data-pr-tooltip={e.isMatch ? "Match" : "Possible match"} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                        <CustomColumn field="domain" sortable headerText="Domain" body={(e: any) => <div className="company-table-container__domain">{parseContent(e.domain)}</div>} />
                        <CustomColumn field="country" sortable headerText="Country" body={(e: any) => <>{parseContent(e.country)}</>} />
                        <CustomColumn
                            field="owner_name"
                            sortable
                            headerText="Owner"
                            body={(e: any) => (
                                <>
                                    {e.owner_email !== "" ? (
                                        <a href={`mailto:${e.owner_email}`}>
                                            <i className="pi pi-envelope"></i>
                                        </a>
                                    ) : (
                                        ""
                                    )}
                                    {parseContent(e.owner_name)}
                                </>
                            )}
                        />
                        <CustomColumn field="industry" sortable headerText="Industry" body={(e: any) => <>{parseContent(INDUSTRY_OPTIONS[e.industry as string])}</>} />
                        <CustomColumn field="hyperscaler" sortable headerText="Hyperscaler" />
                        <CustomColumn
                            field="Case study"
                            headerText="Case study"
                            body={(e) => (
                                <div className="company-table-container__customer_case-study">
                                    {processAndRenderLanguages(sanityApi.sanityCompanies, e)}
                                    {generateSanityLink(sanityApi.sanityCompanies, e)}
                                </div>
                            )}
                        />
                    </CustomDataTable>
                ) : (
                    <ImageText title={t("serviceCards.noResults.title")} description={t("serviceCards.noResults.description")} caseStudies={true} />
                )}
                <div className="pagination-bottom">
                    <Pagination className="pagination" items={sanityApi.hubspotCompanies} pageSize={pageSize} OnPageChange={OnPageChange} currentPage={currentPage} />
                </div>
            </SkeletonWrapper>
        </div>
    )
}
