import { DynamoDBClient, GetItemCommand, PutItemCommand } from "@aws-sdk/client-dynamodb";

export class StorageService {
    client: DynamoDBClient;
    configuration: { user_id: string | undefined, fields: string[] | undefined } = { user_id: '', fields: [] };

    constructor() {
        //access key
        this.client = new DynamoDBClient({ region: "eu-north-1", credentials: { accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY as string, secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY as string } });
    }

    storeConfiguration = async (configuration: { user_id: string, fields: string[] }) => {
        const params = {
            TableName: "hubspot-owner-fields",
            Item: {
                user_id: { S: configuration.user_id },
                fields: { SS: configuration.fields }
            }
        };
        try {
            await this.client.send(new PutItemCommand(params));
        } catch (error) {
            console.error(error);
        }
    }

    getConfiguration = async (user_id: string) => {
        const params = {
            TableName: "hubspot-owner-fields",
            Key: {
                user_id: { S: user_id }
            }
        };
        try {
            const data = await this.client.send(new GetItemCommand(params));
            this.configuration = { user_id: data.Item?.user_id.S, fields: data.Item?.fields.SS };
            return data.Item;
        } catch (error) {
            console.error(error);
        }
    }

    getFields = () => {
        return this.configuration.fields ?? [];
    }

}

export const storageService = new StorageService();