import "./Chips.scss"

export default function (props: { text: string; color: string }) {
    const { text, color } = props

    const firstLetter = text.charAt(0)

    const firstLetterCap = firstLetter.toUpperCase()

    const remainingLetters = text.slice(1)

    const capitalizedWord = firstLetterCap + remainingLetters
    return (
        <div className="chips" style={{ backgroundColor: color }}>
            {capitalizedWord}
        </div>
    )
}
