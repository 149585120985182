import PCG from "../../assets/img/logo-pcg/pcg-logo-horizontal.svg";
import "./Footer.scss";

export const Footer = () => {
  return (
    <footer>
      <main className="footer-wrapper">
        <a href={"/"}>
          <img
            className={"logo-pcg-footer"}
            src={PCG}
            alt={`PCG logo`}
            key={`PCG-logo`}
          />
        </a>
      </main>
    </footer>
  );
};
