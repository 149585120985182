import React, { useEffect, useState } from "react"
import "./ImageText.scss"
import HeadingTag from "../HeadingTag/HeadingTag"
import paperPlaneH from "../../assets/img/graphics/paper-plane-hor.svg"
import paperPlaneV from "../../assets/img/graphics/paper-plane-ver.svg"

export default function ImageText(props: any) {
    const [paperPlane, setPaperPlane] = useState(paperPlaneH)

    useEffect(() => {
        if (window) {
            setPaperPlane(window.innerWidth < 768 ? paperPlaneH : paperPlaneV)
        }
    }, [])
    return (
        <section className={`section__image-text ${props.caseStudies ? "case-studies" : ""}`} data-testid={"section-image-text-wrapper"}>
            <img data-testid={"section-image-text-image"} src={paperPlane} alt={paperPlane} className="" />
            <aside>
                {props.title && (
                    <HeadingTag type="h3" showDotDecoration={false} data-testid={"section-image-text-title"}>
                        {props.title}
                    </HeadingTag>
                )}
                {props.text && <p> {props.text}</p>}
                {props.description && <div data-testid={"section-image-text-description"}>{props.description}</div>}
            </aside>
        </section>
    )
}
