import { useState, useEffect, useRef } from "react"
import { ScrollToTocElement } from "../../../common/services/DataService"
import "./table-of-contents.scss"
import ArrowUp from "../../../common/assets/img/icons/arrow-up.svg"
import ArrowDown from "../../../common/assets/img/icons/arrow-down.svg"
import ContentList from "../../../common/assets/img/icons/list.svg"
import useSectionPosition from "./scroll-section-hook"
import { useTranslation } from "react-i18next"

interface InputNode {
    style: string
    children: Array<any>
}

interface FlatNode {
    style: string
    index: number
    children: Array<any>
}

function buildTreeFlatArray(arr: InputNode[]): FlatNode[] {
    const result: FlatNode[] = []
    const indexMap: { [key: string]: number } = { h2: 0, h3: 0 }

    arr.forEach(({ style, children }) => {
        const node = { style, index: indexMap[style], children }
        indexMap[style]++
        result.push(node)
    })

    return result
}

function ScrollToTocCustomElement(className: string) {
    const elementPosition = Array.from(document.getElementsByClassName(className))[0]?.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.scrollY - 120
    window.scrollTo({ top: Math.round(offsetPosition), behavior: "smooth" })
}

export const TableOfContents = (props: { content: any; desktop: boolean }) => {
    const { t } = useTranslation()
    const { content } = props
    const [transformedArray, setTransformedArray] = useState<Array<FlatNode>>([])
    const [isExpanded, setIsExpanded] = useState(false)

    const activeItemDesc = useSectionPosition({
        items: transformedArray,
    })

    useEffect(() => {
        if (content) {
            const temp = buildTreeFlatArray(content.filter((item: any) => item.style === "h2" || item.style === "h3"))

            setTransformedArray(temp)
        }
    }, [content])

    if (transformedArray.length > 0) {
        if (!props.desktop) {
            return (
                <div className={isExpanded ? "table-of-contents expanded" : "table-of-contents"} data-testid="table-of-contents">
                    <div className="table-of-contents__header" onClick={() => setIsExpanded(!isExpanded)} data-testid="table-of-contents-header">
                        <img src={ContentList} className="table-of-contents__header__icon" alt="List icon"></img>
                        <h5 className="table-of-contents__header__title">{t("internalDocuments.tableOfContents.contents")}</h5>
                        <img src={isExpanded ? ArrowUp : ArrowDown} className="table-of-contents__header__arrow" alt={isExpanded ? "Arrow pointing up" : "Arrow pointing down"}></img>
                    </div>
                    {isExpanded && (
                        <div className="contents-body">
                            {transformedArray.map((item: FlatNode, i: number) => {
                                let paddingLeft = ""
                                if (item.style === "h2") {
                                    paddingLeft = "16px"
                                } else if (item.style === "h3") {
                                    paddingLeft = "32px"
                                } else if (item.style === "h4") {
                                    paddingLeft = "48px"
                                }

                                return (
                                    <div
                                        key={i}
                                        className={`contents-body-item${activeItemDesc === i ? "-active" : ""}`}
                                        style={{ paddingLeft: paddingLeft }}
                                        onClick={() => {
                                            ScrollToTocElement(item.index, item.style)
                                        }}
                                    >
                                        <span>{item.children[0].text}</span>
                                    </div>
                                )
                            })}

                            {/* Authors Section */}
                            <div
                                key={"authors"}
                                className={`contents-body-item${activeItemDesc === "authors" ? "-active" : ""}`}
                                style={{ paddingLeft: "16px" }}
                                onClick={() => {
                                    ScrollToTocCustomElement("section-authors")
                                }}
                            >
                                <span>{"Authors"}</span>
                            </div>

                            {/* Version History Section */}
                            <div
                                key={"version-history"}
                                className={`contents-body-item${activeItemDesc === "version-history" ? "-active" : ""}`}
                                style={{ paddingLeft: "16px" }}
                                onClick={() => {
                                    ScrollToTocCustomElement("version-history")
                                }}
                            >
                                <span>{"Version History"}</span>
                            </div>
                        </div>
                    )}
                </div>
            )
        } else {
            return (
                <div className="contents">
                    <div className="contents-title">{t("internalDocuments.tableOfContents.contents")}</div>
                    <div className="contents-body">
                        {transformedArray.map((item: FlatNode, i: number) => {
                            let paddingLeft = ""
                            if (item.style === "h2") {
                                paddingLeft = "16px"
                            } else if (item.style === "h3") {
                                paddingLeft = "32px"
                            } else if (item.style === "h4") {
                                paddingLeft = "48px"
                            }

                            return (
                                <div
                                    key={i}
                                    className={`contents-body-item${activeItemDesc === i ? "-active" : ""}`}
                                    style={{ paddingLeft: paddingLeft }}
                                    onClick={() => {
                                        ScrollToTocElement(item.index, item.style)
                                    }}
                                >
                                    <span>{item.children[0].text}</span>
                                </div>
                            )
                        })}

                        {/* Authors Section */}
                        <div
                            key={"authors"}
                            className={`contents-body-item${activeItemDesc === "authors" ? "-active" : ""}`}
                            style={{ paddingLeft: "16px" }}
                            onClick={() => {
                                ScrollToTocCustomElement("section-authors")
                            }}
                        >
                            <span>{"Authors"}</span>
                        </div>

                        {/* Version History Section */}
                        <div
                            key={"version-history"}
                            className={`contents-body-item${activeItemDesc === "version-history" ? "-active" : ""}`}
                            style={{ paddingLeft: "16px" }}
                            onClick={() => {
                                ScrollToTocCustomElement("version-history")
                            }}
                        >
                            <span>{"Version History"}</span>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
