import emailIcon from "../../../../common/assets/img/icons/email-icon.svg"
import phoneIcon from "../../../../common/assets/img/icons/phone-icon.svg"
import defaultAvatar from "../../../../common/assets/img/icons/default-avatar.svg"
import { SocialMediaType, renderSocialMediaIcon } from "../../../../utils/utils"
import "./DocumentAuthor.scss"

interface Author {
    image?: any
    name?: string
    jobTitle?: string
    phoneNumber?: string
    email?: string
    socialMedia?: {
        type: string
        link: string
    }
    imageUrl: string
}

interface AuthorProps {
    author: Author
}

export const DocumentAuthor: React.FC<AuthorProps> = ({ author }) => {
    return (
        <div className="author">
            <div className="author__photo-container">
                {author?.imageUrl ? <img alt="author" src={author?.imageUrl} className="author__photo-container__photo" /> : <img className="author__photo-container__photo" data-testid={"default-avatar"} src={defaultAvatar} alt="Author" />}

                {author.socialMedia && author.socialMedia.type != "noSocialMedia" && (
                    <a href={author.socialMedia.link} target="_blank" rel="noreferrer" className="author__photo-container__social-media-icon">
                        {renderSocialMediaIcon(author?.socialMedia?.type as SocialMediaType)}
                    </a>
                )}
            </div>
            <div className="author__details-container">
                <div className="author__details-container__block">
                    {author.name && <h4 className="author__details-container__block__author-name">{author.name}</h4>}
                    {author.jobTitle && <h6 className="author__details-container__block__author-job-title">{author.jobTitle}</h6>}
                </div>
                <div className="author__details-container__block">
                    {author.phoneNumber && (
                        <span className="author__details-container__block__author-info">
                            <img src={phoneIcon} alt="phoneIcon" width={20} className="author__details-container__block__author-info__icon" />
                            <span className="author__details-container__block__author-info__item">{author.phoneNumber}</span>
                        </span>
                    )}
                    {author.email && (
                        <span className="author__details-container__block__author-info">
                            <img src={emailIcon} alt="emailIcon" width={20} className="author__details-container__block__author-info__icon" />
                            <span className="author__details-container__block__author-info__item">{author.email}</span>
                        </span>
                    )}
                </div>
            </div>
        </div>
    )
}
