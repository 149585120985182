import React from "react"
import HeadingTag from "../HeadingTag/HeadingTag"
import Breadcrumbs from "../breadcrumbs/Breadcrumbs"
import "./Hero.scss"
interface Breadcrumb {
    title: string
    link: string
}

interface HeroProps {
    title: string
    breadcrumbs?: Breadcrumb[]
}

export default function Hero({ title, breadcrumbs }: HeroProps) {
    return (
        <div className={`hero`}>
            <section className="hero__content">
                {breadcrumbs && breadcrumbs.length > 0 && <Breadcrumbs items={breadcrumbs} />}
                <HeadingTag className="hero__title" type="h1">
                    {title}
                </HeadingTag>
            </section>
        </div>
    )
}
