import React from "react"
import "./ServiceCard.scss"

interface ServiceCardProps {
    title: string
    description: string
    url: string
    icon: React.ReactNode
    color: string
}

export default function ServiceCard({ title, description, url, icon, color }: ServiceCardProps) {
    return (
        <a href={url} className="service-card" data-testid="service-card">
            <div className="service-card__content">
                {icon && (
                    <div className="service-card__icon" data-testid="service-card-icon">
                        {icon}
                    </div>
                )}
                {title && (
                    <h5 className="service-card__title" data-testid="service-card-title">
                        <span className="service-card__slider" style={{ backgroundColor: color }} />
                        {title}
                    </h5>
                )}
                {description && (
                    <p className="service-card__description" data-testid="service-card-description">
                        {description}
                    </p>
                )}
            </div>
            <span className="service-card__get-started" data-testid="service-card-get-started">
                Get Started
            </span>
        </a>
    )
}
