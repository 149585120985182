import React from "react";

export default function ArrowDecoration(props: any) {
  let className = props.className ? props.className : "";

  return (
    <div className={className} data-testid={"arrow-decoration"}>
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_10431_43313)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.13503 0.5H5.36725L12.8672 8L5.36719 15.5H7.13497L14.193 8.44194L14.635 8L14.193 7.558L7.13503 0.5ZM2.75996 15.5H0.992188L8.49222 8L0.99225 0.5H2.76001L9.81803 7.558L10.26 8L9.81803 8.44194L2.75996 15.5Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_10431_43313">
            <rect
              width="15"
              height="15"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
