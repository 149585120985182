import { Outlet } from "react-router-dom"
import { Navbar } from "../components/navbar/Navbar"
import { MobileNavbar } from "../components/navbar/mobile-navbar/MobileNavbar"
import { Footer } from "../components/footer/Footer"
import "../../common/styles/global.scss"
import "./Layout.scss"
import ScrollUp from "../components/scroll-up/scroll-up"

export const Layout = (props: {}) => {
    return (
        <div className="layout">
            <Navbar />
            <MobileNavbar />
            <div className="outlet">
                <Outlet />
            </div>
            <ScrollUp />
            <Footer />
        </div>
    )
}
