import React from "react"
import Hero from "../common/components/hero/Hero"
import ServiceCard from "../common/components/cards/ServiceCard/ServiceCard"
import HeadingTag from "../common/components/HeadingTag/HeadingTag"
import { ReactComponent as AdminSvg } from "../common/assets/img/icons/admin.svg"
import { ReactComponent as DocumentsSvg } from "../common/assets/img/icons/documents.svg"
import { ReactComponent as CaseStudiesSvg } from "../common/assets/img/icons/case-studies.svg"
import "./HomePage.scss"
import { useAuthentication } from "../common/services/authentication.hook"
import { useTranslation } from "react-i18next"

export const HomePage = () => {
    const auth = useAuthentication()
    const { t } = useTranslation()

    const serviceCardsData = [
        {
            title: t("serviceCards.documents.title"),
            description: t("serviceCards.documents.description"),
            url: "/documents",
            icon: <DocumentsSvg />,
            color: "#FFEA24",
        },
        {
            title: t("serviceCards.caseStudies.title"),
            description: t("serviceCards.caseStudies.description"),
            url: "/case-studies",
            icon: <CaseStudiesSvg />,
            color: "#B7E1F2",
        },
    ]

    return (
        <div>
            <Hero title={t("home.hero.title")} />
            <section className="home-page">
                <div className="text-container">
                    <HeadingTag type="h3">{t("home.title")}</HeadingTag>
                    <p>{t("home.description")}</p>
                </div>

                <div className="cards-container">
                    {serviceCardsData.map((card, index) => (
                        <ServiceCard key={index} title={card.title} description={card.description} url={card.url} icon={card.icon} color={card.color} />
                    ))}
                    {auth.isAdmin() && !auth.loading && <ServiceCard title={t("serviceCards.admin.title")} description={t("serviceCards.admin.description")} url="/admin" icon={<AdminSvg />} color="#1F274B" />}
                </div>
            </section>
        </div>
    )
}
