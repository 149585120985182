import React, { useEffect, useState } from "react"
import ChevronPaginationLeftIcon from "../../assets/img/icons/chevron-pagination-left.svg"
import ChevronPaginationRightIcon from "../../assets/img/icons/chevron-pagination-right.svg"
import "./pagination.scss"

const Pagination = (props) => {
    const totalCount = props?.items?.length || props.totalItems
    const pageSize = props.pageSize
    const pageCount = Math.ceil(totalCount / pageSize)
    const [currentPage, setCurrentPage] = useState(props.currentPage ? props.currentPage : 1)

    function fillNumArray(from, to, order = "forwards") {
        const result = []

        if (order === "forwards") {
            for (let i = from; i <= to; i++) {
                result.push(i)
            }
        } else if (order === "backwards") {
            for (let i = from; i >= to; i--) {
                result.push(i)
            }
        }

        return result
    }

    if (currentPage > pageCount) {
        props.OnPageChange(1)
    }

    const allPages = fillNumArray(1, pageCount)
    const [filteredPages, setFilteredPages] = useState([...allPages])

    useEffect(() => {
        if (props.currentPage) {
            setCurrentPage(props.currentPage)
        }
    }, [props.currentPage])

    useEffect(() => {
        if (pageCount <= 7) {
            setFilteredPages(allPages)
        } else if (currentPage < 5) {
            setFilteredPages([...fillNumArray(1, 5), "...", pageCount])
        } else if (pageCount < currentPage + 4) {
            setFilteredPages([1, "...", ...fillNumArray(pageCount - 4, pageCount)])
        } else {
            setFilteredPages([1, "...", ...fillNumArray(currentPage - 1, currentPage + 1), "...", pageCount])
        }
    }, [currentPage, pageCount])

    if (allPages.length > 1) {
        return (
            <React.Fragment>
                <section className={"pagination"} data-testid={"pagination-wrapper"}>
                    {currentPage > 1 && <img data-testid="prev-link" className={"pagination__previous"} alt="Takes you to the previous page of the pagination" src={ChevronPaginationLeftIcon} onClick={() => props.OnPageChange(currentPage - 1)}></img>}
                    {filteredPages.map((page, index) =>
                        page === "..." ? (
                            <div data-testid="pagination-dots" className="pagination__dots" key={index}>
                                {page}
                            </div>
                        ) : (
                            <a
                                key={index}
                                data-testid={`page-number-${page}`}
                                onClick={() => {
                                    props.OnPageChange(page)
                                }}
                                className={currentPage === page ? "pagination__page--active" : "pagination__page"}
                            >
                                {page}
                            </a>
                        )
                    )}
                    {currentPage < allPages.length && <img data-testid="next-link" className={"pagination__next"} alt="Takes you to the next page of the pagination" src={ChevronPaginationRightIcon} onClick={() => props.OnPageChange(currentPage + 1)}></img>}
                </section>
            </React.Fragment>
        )
    } else {
        return null
    }
}

export default Pagination
