import React, { useState } from "react"
import { GetSanityLink, ScrollToElement, GetPageByKey, GetAltText } from "../../services/DataService"
import SectionCallToAction from "../calltoaction/CallToAction"
import PanAndZoomDiagram from "../panandzoomdiagram/PanAndZoomDiagram"
import CardHighlight from "../cards/cardhighlight/CardHighlight"
import ExternalImage from "../../assets/img/icons/external-link-pcg.svg"
import Button from "../button/Button"
import CopyIcon from "../../assets/img/icons/copy-icon.svg"
import QuoteIcon from "../../assets/img/icons/quote.svg"
import { PortableText } from "@portabletext/react"
import SyntaxHighlighter from "react-syntax-highlighter"
import ReactPlayer from "react-player"
import { docco } from "react-syntax-highlighter/dist/cjs/styles/hljs"
import { GetColor } from "../../services/ColorService"
import "./RichText.scss"

export const RichText = (props: { item: Array<any>; color: string; className: string }) => {
    const bulletColor = props.color
    const processedItem: Array<any> = props.item.map((i) => {
        if (typeof i !== "string" && !i.markDefs) {
            i.markDefs = []
        }
        return i
    })
    function findElementsWithListItem(elements: Array<any>, result: Array<any> = []): Array<any> {
        if (elements.length === 0) {
            return result
        }
        if (elements[0].listItem) {
            result.push(elements[0])
            return findElementsWithListItem(elements.slice(1), result)
        } else {
            return result
        }
    }

    const mappedData: Array<any> = processedItem
        .map((element, index) => {
            if (index !== processedItem.length - 1 && element._type === "customImage") {
                element.type = element.type ?? "block"
                if (element.type === "inline") {
                    element.width = "small"
                    const richTextToWrapAround = processedItem[index + 1].listItem ? findElementsWithListItem(processedItem.slice(index + 1)) : processedItem[index + 1]
                    if (richTextToWrapAround._type === "block") {
                        richTextToWrapAround.filterOut = true
                        element.richTextToWrapAround = { ...richTextToWrapAround }
                    }
                }
            } else if (element._type === "block" && element.listItem === "bullet") {
                element._type = "bullet"
            }
            return element
        })
        .filter((element) => !element.filterOut)

    const ListSerializer = (props: any) => {
        let listStyle = ""

        switch (props.value.level % 3) {
            case 0:
                listStyle = "lower-roman"
                break
            case 1:
                listStyle = "decimal"
                break
            case 2:
                listStyle = "lower-alpha"
                break
            default:
                listStyle = "decimal"
        }

        return props.value.listItem === "number" ? (
            <ol className={props.value.level > 1 ? "nested" : ""} style={{ listStyle: listStyle }}>
                {props.children}
            </ol>
        ) : (
            <ul>{props.children}</ul>
        )
    }
    const ListItemSerializer = (props: any) => {
        if (props.value.listItem == "bullet")
            return (
                <li>
                    <div data-testid={"square-bullet"} className={"square-bullet"} style={bulletColor ? { backgroundColor: bulletColor } : {}} />
                    <div>{Array.isArray(props.children) ? props?.children?.map((element: any, index: number) => <React.Fragment key={index}>{element}</React.Fragment>) : <React.Fragment>{props.children}</React.Fragment>}</div>
                </li>
            )
        else {
            return (
                <span>
                    <li>{props.children}</li>
                </span>
            )
        }
    }

    const ExternalLinkSerializer = (children: any, value: any) => {
        if (value?.href) {
            let link = value.href
            return (
                <React.Fragment>
                    <a href={link} target="_blank" rel="noreferrer">
                        {children}
                        <img className={"icon--external-link"} src={ExternalImage} alt="External Link" width="20" height="20" />
                    </a>
                </React.Fragment>
            )
        } else {
            return <span>{children}</span>
        }
    }

    const InternalLinkSerializer = (children: any, value: any) => {
        let linkUrl = "/"
        if (value.newTab) {
            return (
                <a href={linkUrl} target={"_blank"} rel="noreferrer">
                    {children}
                </a>
            )
        } else {
            return <a href={linkUrl}>{children}</a>
        }
    }

    const InPageLinkSerializer = (children: any, value: any) => {
        if (value.elementId) {
            return (
                <a href="javascript:void(0)" onClick={() => ScrollToElement(value.elementId, "nearest", "start", 90)}>
                    {children}
                </a>
            )
        } else {
            return <span>{children}</span>
        }
    }

    const LinkSerializer = (props: any) => {
        if (props && Object.keys(props).length > 1) {
            const { children, value } = props
            if (value?.type) {
                if (value.type === "external") {
                    return ExternalLinkSerializer(children, value)
                } else if (value.type === "internal" && value.page) {
                    return InternalLinkSerializer(children, value)
                } else if (value.type === "in_page" && value.elementId) {
                    return InPageLinkSerializer(children, value)
                } else {
                    return <span>{children}</span>
                }
            } else {
                if (!value?.internal) {
                    return ExternalLinkSerializer(children, value)
                } else if (value?.internal && value?.page) {
                    return InternalLinkSerializer(children, value)
                } else {
                    return <span>{children}</span>
                }
            }
        }
    }

    const ColorsSerializers = (props: any) => {
        if (props.value.value) {
            return <span style={{ color: `${props.value.value}` }}>{props.children}</span>
        } else {
            return null
        }
    }

    const CodeSerializer = (props: any) => {
        const [showTooltip, setShowTooltip] = useState(false)

        const copyToClipboard = async (code: string) => {
            try {
                await navigator.clipboard.writeText(code)
                setShowTooltip(true)
            } catch (err) {
                console.error("Failed to copy code: ", err)
            }
        }

        const handleMouseOut = () => {
            if (showTooltip) {
                setShowTooltip(false)
            }
        }

        const customStyle: any = {
            margin: "0px",
            padding: "12px",
            backgroundColor: GetColor("clr-gray-100"),
            overflowX: "hidden",
        }

        if (props.value.code) {
            return (
                <div className={"code-snippet"} data-testid={"code-snippet"}>
                    <div className={"code-snippet__header"}>
                        <span className={"code-snippet__language"}>{props.value.language}</span>
                        <div className="code-snippet__button-wrapper">
                            <span className={`code-snippet__tooltip ${showTooltip ? "code-snippet__tooltip--visible" : ""}`}>Copy Code</span>
                            <span onClick={() => copyToClipboard(props.value.code)} onMouseOut={handleMouseOut} data-testid={"copy-button"} className="code-snippet__copy-button">
                                <img className="code-snippet__copy-icon" src={CopyIcon} alt={"copy-button"} />
                            </span>
                        </div>
                    </div>
                    <SyntaxHighlighter language={props.value.language} style={docco} wrapLongLines={true} customStyle={customStyle}>
                        {props.value.code}
                    </SyntaxHighlighter>
                </div>
            )
        } else {
            return null
        }
    }
    const QuoteSerializer = (props: any) => {
        if (props.value) {
            return (
                <div className={"custom-quote"} data-testid={"custom-quote"}>
                    <div className={"custom-quote__decorations-wrapper"}>
                        <img className="custom-quote__decorations-wrapper__icon" src={QuoteIcon}></img>
                        <div className="custom-quote__decorations-wrapper__top-line" />
                    </div>
                    {props.value.quoteContent && <div className={"custom-quote__content"}>{props.value.quoteContent}</div>}
                    <div className={"custom-quote__author-wrapper"}>
                        {props.value.authorImg && <img src={props.value.authorImg.asset.url} alt="Profile" className={"custom-quote__author-wrapper__img"} />}
                        <div className={"custom-quote__author-wrapper__content-wrapper"}>
                            {props.value.author && <div className={"custom-quote__author-wrapper__content-wrapper__author"}>{props.value.author}</div>}
                            {(props.value.role || props.value.companyName) && (
                                <div className={"custom-quote__author-wrapper__content-wrapper__author-details"}>
                                    {props.value.role && <div className={`custom-quote__author-wrapper__content-wrapper__author-details__item ${!props.value.author ? "bolded" : ""}`}>{props.value.role}</div>}
                                    {props.value.role && props.value.companyName && <div className={`custom-quote__author-wrapper__content-wrapper__author-details__item ${!props.value.author ? "bolded" : ""}`}>&nbsp;@&nbsp;</div>}

                                    {props.value.companyName && <div className={`custom-quote__author-wrapper__content-wrapper__author-details__item ${!props.value.author ? "bolded" : ""}`}>{props.value.companyName}</div>}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="custom-quote__bottom-line" />
                </div>
            )
        } else {
            return null
        }
    }
    const CustomDiagramSerializer = (props: any) => {
        const { _key, image, title, description } = props.value

        if (image && image.asset) {
            return <PanAndZoomDiagram altText={GetAltText(props, _key)} image={image} key={_key} title={title} description={description} />
        } else {
            return null
        }
    }
    const EmbeddedVideoSerializers = (props: any) => {
        const url = props.value.url
        return (
            <div className={"player-wrapper"} data-testid={"rich-text-video"}>
                <ReactPlayer
                    className={"react-player"}
                    url={url}
                    controls
                    width="100%"
                    height="100%"
                    config={{
                        vimeo: {
                            playerOptions: {
                                playsinline: 1,
                            },
                        },
                    }}
                />
            </div>
        )
    }

    const CtaSectionSerializer = (props: any) => {
        const { title, description, link } = props.value
        let href, type
        if (link?.linkToggle) {
            href = GetSanityLink(link)
            type = link.type
        }

        if (props.value) {
            return <SectionCallToAction className={"call-to-action"} title={title} description={description} buttonText={link?.title} href={type === "in_page" ? null : href} elementId={link?.elementId ? link?.elementId : null} linkType={type} newTab={link?.newTab} color={"clr-tertiary-500"} />
        } else {
            return null
        }
    }

    const SmallCtaSectionSerializer = (props: any) => {
        const { link, position } = props.value
        let href
        let type
        if (link?.linkToggle) {
            href = GetSanityLink(link)
            type = link.type
        }

        return (
            <div className={`rich-text-small-cta ${position}`}>
                <Button type={"button"} className={"btn__primary small-cta"} text={link.title && link.title["en"] ? link.title["en"] : "Learn More"} href={href} linkType={link.type} newTab={link.newTab} />
            </div>
        )
    }

    const CustomImageSerializer = (props: any) => {
        const { _key, image, imageMobile, type, position, title, description, richTextToWrapAround } = props.value
        const width = type === "inline" ? "small" : props.value.width
        const showCaption = !!((title && title["en"]) || (description && description["en"]))
        if (image && image.asset) {
            return (
                <React.Fragment>
                    <div key={_key} className={`${type ? type : "block"} ${width ? width : "big"} ${position ? position : "center"}`}>
                        <div className={"rich-text-image-wrapper"} data-testid={"rich-text-image-wrapper"}>
                            <img src={image.asset.url} alt={GetAltText(props, _key) || ""} className={imageMobile ? "image-responsive--desktop" : ""} loading="lazy" />
                            {imageMobile && <img src={imageMobile.asset.url} alt={GetAltText(props, _key) || ""} className={"image-responsive--mobile"} loading="lazy" />}
                            {showCaption && (
                                <div className={"image-description"}>
                                    {props.value.title && props.value.title["en"] && <p className={"image-description__title"}>{props.value.title["en"]}</p>}
                                    {props.value.description && props.value.description["en"] && <p className={"image-description__description"}>{props.value.description["en"]}</p>}
                                </div>
                            )}
                        </div>
                        <div className={"rich-text-to-wrap-around"}>{richTextToWrapAround && <PortableText value={richTextToWrapAround} components={serializers} />}</div>
                    </div>
                </React.Fragment>
            )
        } else {
            return null
        }
    }

    const InPageLinkTargetSerializer = (props: any) => {
        if (props && Object.keys(props).length > 1) {
            const { children, value } = props
            if (value?._type === "in_page_link_target") {
                return <span id={value?.elementId}>{children}</span>
            } else {
                return <span>{children}</span>
            }
        } else {
            return null
        }
    }

    // const HighLightLinkSerializer = (props: any) => {
    //   console.log("Props: ", props);
    //   if (props.value && props.value?.highlightItem)
    //     return (
    //       <CardHighlight
    //         title={props.value.title}
    //         item={props.value.highlightItem}
    //       />
    //     );
    // };

    const serializers = {
        types: {
            code: CodeSerializer,
            customQuote: QuoteSerializer,
            customImage: CustomImageSerializer,
            customDiagram: CustomDiagramSerializer,
            embeddedVideo: EmbeddedVideoSerializers,
            ctaSection: CtaSectionSerializer,
            smallCtaSection: SmallCtaSectionSerializer,
            // highlightLink: HighLightLinkSerializer,
        },
        list: ListSerializer,
        listItem: ListItemSerializer,
        marks: {
            link: LinkSerializer,
            color: ColorsSerializers,
            in_page_link_target: InPageLinkTargetSerializer,
        },
    }

    if (mappedData) {
        return (
            <div className={`rich-text ${props.className ? props.className : ""}`}>
                <PortableText value={mappedData} components={serializers}></PortableText>
            </div>
        )
    }
    return null
}
