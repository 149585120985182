import { GetObjectCommand, PutObjectCommand, S3Client } from "@aws-sdk/client-s3"
import { AsyncBuffer, parquetMetadata, parquetRead } from 'hyparquet';
import { COMPANY_FIELDS, OWNER_FIELDS } from "../constants";
import { mapResultToObject } from "../mapper_functions";

const OWNER_GOAL_MAP_KEY = 'owner_goals/owner-goal-map.json';
const OWNER_COUNTRY_MAP_KEY = 'owner_countries/owner-country-map.json';

export class S3Driver {
    client: S3Client;
    schema: any[] = [
        { name: 'owner_id', order: 0 },
        { name: 'email', order: 2 },
        { name: 'first_name', order: 3 },
        { name: 'last_name', order: 4 },

    ];
    data: any;
    constructor(
        public bucket: string = "",
        public region: string = "",
        public accessKeyId: string = "",
        public secretAccessKey: string = "",
    ) {
        this.client = new S3Client({
            region: region,
            credentials: {
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey
            }
        });
    }

    upload = async (key: string) => { 
        
    }

    mapRow(row: any[]) {
        let temp_obj: { [index: string]: {} } = {};
        this.schema.forEach((field, i) => {
            temp_obj[field.name as string] = row[field.order];
        });
        return temp_obj;
    }

    //TODO: REFACTOR THIS TO MAP TO PROPER OBJECTS
    //TODO: PROBABLY NEED TO ADD A FLOW IN ONCOMPLETE TO MAP TO OBJECTS
    download = async (key: string, onComplete: Function, propertyName: string) => {
        let getObjectCommand = new GetObjectCommand({
            Bucket: this.bucket,
            Key: key
        });
        let parquetData = await (await this.client.send(getObjectCommand)).Body?.transformToByteArray();
        await parquetRead({
            columns: propertyName === 'owners' ? OWNER_FIELDS : COMPANY_FIELDS,
            file: parquetData?.buffer as AsyncBuffer,
            onComplete: (data: any) => {
                let mappedProperties = data.map((row: any) => mapResultToObject(propertyName, row));
                onComplete(mappedProperties);
            },
            metadata: parquetMetadata(parquetData?.buffer as ArrayBuffer)
        });
    }

    getCountries = async () => {
        let getObjectCommand = new GetObjectCommand({
            Bucket: process.env.REACT_APP_S3_ATHENA_RESOURCES_BUCKET,
            Key: OWNER_COUNTRY_MAP_KEY
        });

        let result = await (await this.client.send(getObjectCommand)).Body?.transformToString();
        return result?.split('\n').map((x) => JSON.parse(x));
    }

    getGoals = async () => {
        let getObjectCommand = new GetObjectCommand({
            Bucket: process.env.REACT_APP_S3_ATHENA_RESOURCES_BUCKET,
            Key: OWNER_GOAL_MAP_KEY
        });

        let result = await (await this.client.send(getObjectCommand)).Body?.transformToString();
        return result?.split('\n').map((x) => { if (x) return JSON.parse(x) });
    }

    saveCountries = async (countries: string) => {
        let params = {
            Bucket: process.env.REACT_APP_S3_ATHENA_RESOURCES_BUCKET,
            Key: OWNER_COUNTRY_MAP_KEY,
            Body: countries
        };
        await this.client.send(new PutObjectCommand(params));
    }

    saveGoals = async (goals: string) => {
        let params = {
            Bucket: process.env.REACT_APP_S3_ATHENA_RESOURCES_BUCKET,
            Key: OWNER_GOAL_MAP_KEY,
            Body: goals
        };
        await this.client.send(new PutObjectCommand(params));
    }

    saveCallCenterFile = async (file: File, country: string, owner_id: string) => {
        let key = `${country}/${owner_id}/${file.name}`;
        let params = {
            Bucket: process.env.REACT_APP_S3_DATA_PROCESSING_BUCKET,
            Key: key,
            Body: file
        };
        let result = await this.client.send(new PutObjectCommand(params));
        return { result, key };
    }

}