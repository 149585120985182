import App from "./App"
import { HomePage } from "./homePage/HomePage"
import { AdminDashboard } from "./admin/components/dashboard/AdminDashboard"
import { SalesPeople } from "./admin/components/salespeople/SalesPeople"
import { AdminLayout } from "./admin/layout/AdminLayout"
import { Login } from "./auth/login/Login"
import { CaseStudies } from "./website-integration/components/caseStudies/CaseStudies"
import { InternalDocumentPage } from "./documents/components/internalDocumentPage/InternalDocumentPage"
import { InternalDocumentsLayout } from "./documents/components/InternalDocumentsLayout/InternalDocumentsLayout"
import { InternalDocuments } from "./documents/components/InternalDocuments/InternalDocuments"

export const routes = [
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/",
                element: <HomePage />,
            },
            {
                path: "/:code",
                element: <> </>,
            },
            {
                path: "/admin",
                element: <AdminLayout />,
                children: [
                    {
                        path: "/admin/",
                        element: <AdminDashboard />,
                    },
                    {
                        path: "/admin/salespeople",
                        element: <SalesPeople />,
                    },
                ],
            },
            {
                path: "/case-studies",
                element: <CaseStudies />,
            },
            {
                path: "/documents",
                element: <InternalDocumentsLayout />,
                children: [
                    {
                        path: "/documents/",
                        element: <InternalDocuments />,
                    },
                    {
                        path: "/documents/:id",
                        element: <InternalDocumentPage />,
                    },
                ],
            },
        ],
    },
    {
        path: "/login",
        element: <Login />,
    },
]
