import { filterAndMergeHubspotCompaniesWithStudies, mergeClientAndStudyData, normalizeString, normalizeValues, parseContent } from "../util/customer_table_render_helpers"
import { useState } from "react"
import axios from "axios"
import { GetCaseStudiesRequest } from "../models/GetCaseStudiesRequest"
import { GetDocumentsRequest } from "../models/GetDocumentsRequest"
import { S3OwnersContext } from "../../common/services/s3-framework/models/S3OwnersContext"
import { INDUSTRY_OPTIONS } from "../constants/industry"
import { Client, CaseStudy, Service } from "../types"
import { GetClientsByIdRequest } from "../models/GetClientsByIdRequest"
import { getServicesByIdRequest } from "../models/GetServicesByIdRequest"
import { GetInternalDocumentByIdRequest } from "../models/GetInternalDocumentByIdRequest"
import { GetInternalDocumentsRequest } from "../models/GetInternalDocumentsRequest"

export const useSanityApi = () => {
    let clients: Client[] = []
    let caseStudies: { [key: string]: CaseStudy } = {}
    let services: Service[] = []
    const s3Context = new S3OwnersContext()

    const [sanityCompanies, setSanityCompanies] = useState<any[]>([])
    const [hubspotCompanies, setHubspotCompanies] = useState<any[]>([])
    const [companies, setAllHubspotCompanies] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [countries, setCountries] = useState<any>([])
    const [industries, setIndustries] = useState<any>([])
    const [owners, setOwners] = useState<any>([])
    const [languages, setLanguages] = useState<any>([])

    const [documents, setDocuments] = useState<any>([])
    const [departments, setDepartments] = useState<any>([])

    const getCaseStudies = async () => {
        let result = await axios.post(process.env.REACT_APP_SANITY_API_URL as string, new GetCaseStudiesRequest(), {
            headers: {
                Accept: "application/json",
            },
        })
        caseStudies = result.data
    }

    const getClientsById = async (clientIds: string[]) => {
        let result = await axios.post(process.env.REACT_APP_SANITY_API_URL as string, new GetClientsByIdRequest(clientIds), {
            headers: {
                Accept: "application/json",
            },
        })
        clients = result.data.filter((client: any) => client != null)
    }

    const getServicesById = async (serviceIds: string[]) => {
        let result = await axios.post(process.env.REACT_APP_SANITY_API_URL as string, new getServicesByIdRequest(serviceIds), {
            headers: {
                Accept: "application/json",
            },
        })

        services = result.data
    }

    const getInternalDocumentById = async (documentId: string) => {
        setLoading(true)

        let result = await axios
            .post(process.env.REACT_APP_SANITY_API_URL as string, new GetInternalDocumentByIdRequest(documentId), {
                headers: {
                    Accept: "application/json",
                },
            })
            .finally(() => {
                setLoading(false)
            })
        return result.data[0]
    }

    const extractUsedDepartments = async (documents: any[]) => {
        const departmentsMap = new Map<string, any>()

        documents.forEach((document: any) => {
            const { viewingRights, owner, teamsWithViewingRights } = document

            if ((viewingRights === "owner" || viewingRights === "teams") && owner?._id) {
                departmentsMap.set(owner._id, owner)
            }

            if (viewingRights === "teams" && Array.isArray(teamsWithViewingRights)) {
                teamsWithViewingRights.forEach((team: any) => {
                    if (team?._id) {
                        departmentsMap.set(team._id, team)
                    }
                })
            }
        })

        const departmentsWithProperties = Array.from(departmentsMap.values()).map((department: any) => ({
            ...department,
            value: department.name,
            key: department.abbreviation,
            label: department.name,
        }))

        return departmentsWithProperties
    }

    const getInternalDocuments = async () => {
        setLoading(true)
        try {
            const requestPayload = new GetInternalDocumentsRequest()

            const result = await axios.post(process.env.REACT_APP_SANITY_API_URL as string, requestPayload, {
                headers: {
                    Accept: "application/json",
                },
            })

            if (result?.data) {
                setDocuments(result.data)

                const uniqueDepartments = await extractUsedDepartments(result.data)

                setDepartments(uniqueDepartments)
            }
        } catch (error) {
            console.error("Error fetching internal documents:", error)
            throw error
        } finally {
            setLoading(false)
        }
    }

    const getAndLoadProfileForCustomers = async () => {
        const languageMap: { [key: string]: string } = {
            ch: "German",
            en: "English",
            se: "Swedish",
            gr: "Greek",
        }

        try {
            await getCaseStudies()
            const clientIds = Object.keys(caseStudies || {})
            const serviceIds = Object.values(caseStudies || {})
                .map((cs) => cs.serviceId)
                .filter((id) => id != null)
            await Promise.all([getClientsById(clientIds), getServicesById(serviceIds)])

            let clientsList = mergeClientAndStudyData(clients, caseStudies, services)
            setSanityCompanies(clientsList)
            s3Context.getOwners((_owners: any) => {
                s3Context.getCompanies((companies: any) => {
                    try {
                        let hubspotProperties = filterAndMergeHubspotCompaniesWithStudies(companies, _owners, clientsList)
                        setAllHubspotCompanies(hubspotProperties)
                        setIndustries(mapItems(Array.from(new Set(hubspotProperties.map((item: any) => parseContent(INDUSTRY_OPTIONS[item.industry as string]))))))
                        setCountries(mapItems(Array.from(new Set(hubspotProperties.map((item: any) => item.country)))))
                        setOwners(mapItems(Array.from(new Set(hubspotProperties.map((item: any) => item.owner_name)))))
                        const languagesWithFullNames = hubspotProperties.flatMap((item: any) => item.languages).map((code: string) => languageMap[code] || code) // Fallback to the code if no mapping is found

                        setLanguages(mapItems(Array.from(new Set(languagesWithFullNames))))
                        setLoading(false)
                    } catch (error) {
                        console.log(error)
                        setLoading(false)
                    }
                })
            })
        } catch (error) {
            console.error("Error loading profiles for customers:", error)
        }
    }

    const mapItems = (items: string[]) => {
        const mappedList: { [key: string]: string } = {}
        items.forEach((item) => {
            const normalizedKey = normalizeString(item)
            mappedList[normalizedKey] = item
        })
        return mappedList
    }

    return {
        sanityCompanies,
        companies,
        loading,
        hubspotCompanies,
        industries,
        countries,
        owners,
        languages,
        documents,
        departments,
        getAndLoadProfileForCustomers,
        setHubspotCompanies,
        getInternalDocumentById,
        getInternalDocuments,
    }
}
