import React from "react"

type FeaturedTagProps = {
    type: TypesMapKeys
}
export default function FeaturedTag({ type }: FeaturedTagProps) {
    if (!type) {
        return
    }

    const colors = typeToColor(type)

    return (
        <svg width="116" height="49" viewBox="0 0 116 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3548 18.2535L0 0H108.947C112.29 0 115 2.91015 115 6.5V42.5C115 43.3536 114.844 44.1988 114.539 44.9874C114.236 45.7761 113.789 46.4926 113.227 47.0962C112.666 47.6998 111.998 48.1786 111.263 48.5052C110.529 48.8319 109.743 49 108.947 49V36H0L13.3548 18.2535Z" fill={colors.backgroundColor} />
            <path d="M108.95 36C109.745 36 110.532 36.1681 111.265 36.4948C111.999 36.8214 112.667 37.3002 113.228 37.9038C113.79 38.5074 114.236 39.2239 114.539 40.0126C114.844 40.8012 115 41.6464 115 42.5C115 43.3536 114.844 44.1988 114.539 44.9874C114.236 45.7761 113.79 46.4926 113.228 47.0962C112.667 47.6998 111.999 48.1786 111.265 48.5052C110.532 48.8319 109.745 49 108.95 49V42.5V36Z" fill="black" fill-opacity="0.1" />
            <path
                d="M90.6513 23.0521V21.0631H92.4193C92.8613 21.0631 93.26 20.9981 93.6153 20.8681C93.9706 20.7295 94.274 20.5388 94.5253 20.2961C94.7766 20.0448 94.9673 19.7458 95.0973 19.3991C95.2273 19.0438 95.2923 18.6538 95.2923 18.2291C95.2923 17.7871 95.2273 17.3928 95.0973 17.0461C94.9673 16.6995 94.7766 16.4048 94.5253 16.1621C94.274 15.9195 93.9706 15.7375 93.6153 15.6161C93.26 15.4861 92.8613 15.4211 92.4193 15.4211H90.6513V13.4321H92.3023C93.1603 13.4321 93.91 13.5578 94.5513 13.8091C95.1926 14.0605 95.73 14.4028 96.1633 14.8361C96.5966 15.2695 96.9173 15.7678 97.1253 16.3311C97.342 16.8858 97.4503 17.4708 97.4503 18.0861V18.3721C97.4503 18.9441 97.342 19.5118 97.1253 20.0751C96.9173 20.6298 96.5966 21.1325 96.1633 21.5831C95.73 22.0251 95.1926 22.3805 94.5513 22.6491C93.91 22.9178 93.1603 23.0521 92.3023 23.0521H90.6513ZM88.7793 23.0521V13.4321H90.9113V23.0521H88.7793Z"
                fill={colors.textColor}
            />
            <path d="M81.041 23.0003V13.5103H83.121V23.0003H81.041ZM82.861 23.0003V21.2323H87.008V23.0003H82.861ZM82.861 19.0613V17.2933H86.709V19.0613H82.861ZM82.861 15.2783V13.5103H86.917V15.2783H82.861Z" fill={colors.textColor} />
            <path
                d="M71.5254 23.0001V13.4321H73.6574V23.0001H71.5254ZM77.2844 23.0001L74.5024 18.9181H76.8424L79.7544 23.0001H77.2844ZM73.0854 19.9581V18.2811H75.4254C75.7374 18.2811 76.0061 18.2205 76.2314 18.0991C76.4654 17.9691 76.6431 17.7915 76.7644 17.5661C76.8944 17.3321 76.9594 17.0635 76.9594 16.7601C76.9594 16.4568 76.8944 16.1925 76.7644 15.9671C76.6431 15.7331 76.4654 15.5555 76.2314 15.4341C76.0061 15.3041 75.7374 15.2391 75.4254 15.2391H73.0854V13.4321H75.2564C76.0451 13.4321 76.7297 13.5535 77.3104 13.7961C77.8911 14.0301 78.3374 14.3811 78.6494 14.8491C78.9614 15.3171 79.1174 15.9065 79.1174 16.6171V16.8251C79.1174 17.5271 78.9571 18.1121 78.6364 18.5801C78.3244 19.0395 77.8781 19.3861 77.2974 19.6201C76.7254 19.8455 76.0451 19.9581 75.2564 19.9581H73.0854Z"
                fill={colors.textColor}
            />
            <path
                d="M65.391 23.2473C64.4983 23.2473 63.74 23.0826 63.116 22.7533C62.5006 22.4153 62.0326 21.9386 61.712 21.3233C61.3913 20.7079 61.231 19.9799 61.231 19.1393V13.5103H63.363V19.2173C63.363 19.6333 63.441 19.9973 63.597 20.3093C63.7616 20.6126 63.9913 20.8466 64.286 21.0113C64.5893 21.1759 64.9576 21.2583 65.391 21.2583C65.833 21.2583 66.2013 21.1759 66.496 21.0113C66.7993 20.8466 67.029 20.6126 67.185 20.3093C67.341 20.0059 67.419 19.6419 67.419 19.2173V13.5103H69.551V19.1393C69.551 19.9799 69.3906 20.7079 69.07 21.3233C68.7493 21.9386 68.2813 22.4153 67.666 22.7533C67.0506 23.0826 66.2923 23.2473 65.391 23.2473Z"
                fill={colors.textColor}
            />
            <path d="M55.2675 23.0003V15.1093H57.3995V23.0003H55.2675ZM52.6025 15.3693V13.5103H60.0775V15.3693H52.6025Z" fill={colors.textColor} />
            <path d="M43.1353 23.0003L46.2683 13.5103H49.7133L52.9633 23.0003H50.7533L48.1273 14.9793L48.8033 15.2523H47.1263L47.8283 14.9793L45.2673 23.0003H43.1353ZM45.5143 20.6473L46.1643 18.8793H49.8823L50.5453 20.6473H45.5143Z" fill={colors.textColor} />
            <path d="M36.2939 23.0003V13.5103H38.3739V23.0003H36.2939ZM38.1139 23.0003V21.2323H42.2609V23.0003H38.1139ZM38.1139 19.0613V17.2933H41.9619V19.0613H38.1139ZM38.1139 15.2783V13.5103H42.1699V15.2783H38.1139Z" fill={colors.textColor} />
            <path d="M29 23.0003V13.5103H31.132V23.0003H29ZM30.872 19.1783V17.4103H34.538V19.1783H30.872ZM30.872 15.2783V13.5103H34.694V15.2783H30.872Z" fill={colors.textColor} />
        </svg>
    )
}

type TypesMapKeys = keyof typeof typesMap

const typesMap = {
    P: {
        backgroundColor: "#ffea24",
        textColor: "#000000",
    },
    Pr: {
        backgroundColor: "#1f274b",
        textColor: "#ffffff",
    },
    G: {
        backgroundColor: "#b7e1f2", // $
        textColor: "#000000",
    },
    C: {
        backgroundColor: "#43286f",
        textColor: "#ffffff",
    },
    T: {
        backgroundColor: "#5ec6f2",
        textColor: "#000000",
    },
    DB: {
        backgroundColor: "#c0d0df",
        textColor: "#000000",
    },
    KPIs: {
        backgroundColor: "#fdf7c9",
        textColor: "#000000",
    },
    Wb: {
        backgroundColor: "#113d67",
        textColor: "#ffffff",
    },
    Pt: {
        backgroundColor: "#b9c4db",
        textColor: "#000000",
    },
    KB: {
        backgroundColor: "#e3f3fa",
        textColor: "#000000",
    },
} as const

function typeToColor(type: TypesMapKeys): { backgroundColor: string; textColor: string } {
    return typesMap[type]
}
