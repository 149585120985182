import { Outlet, useNavigate } from 'react-router-dom';
import './AdminLayout.scss';
import { useEffect } from 'react';
import { useAuthentication } from '../../common/services/authentication.hook';

export const AdminLayout = (props: {}) => {
    const navigate = useNavigate();
    const auth = useAuthentication();

    useEffect(() => {
        if (!auth.loading && !auth.isAdmin()) {
          navigate("/", { replace: true });
        }
      }, [auth.loading, auth.isAdmin()]);
      
    return (
        <>
            <h3>PCG Sales Hub - Admin (pre-alpha version)</h3>
            <div>
                <Outlet />
            </div>
        </>
    )
}