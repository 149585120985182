export class ParquetCompanyPayload {
    constructor(
        public hs_object_id: string = "",
        public name: string = "",
        public hubspot_owner_id: string = "",
        public country: string = "",
        public domain: string = "",
        public industry: string = "",
        public lifecyclestage: string = ""
    ) { }
}